import { InferType } from 'yup';
import * as yup from 'yup';
import { FormPropertiesType } from './types';
import { Model } from './index';
import { fileSchema } from './sub/file';
import { notificationSchema } from './sub/notification';

export const projectDateSchema = yup.object({
    startDate: yup.date().required().label('Start datum'),
    endDate: yup.date().required().label('Eind datum'),
    purchaseRate: yup.number().label('Inkoop uurtarief'),
    feeRate: yup.number().label('Fee per uur'),
    hourRate: yup.number().positive().required().label('Verkoop uurtarief'),
    contractHours: yup.number().required().label('Contract uren'),
    invoiceSchema: yup.mixed().required().reference('invoice_schema').label('Facturatieschema'),
});

const schema = yup.object({
    id: yup.string().label('ID'),
    driveFolderID: yup.string().label('Drive folder ID'),
    language: yup.string().required('Choose one of the languages').label('Taal'),
    name: yup.string().required().label('Naam'),
    description: yup.string().label('Beschrijving'),
    projectNumber: yup.string().required().label('Projectnummer'),
    PO: yup.string().required().label('PO'),
    customer: yup.mixed().reference('customer').required().label('Klant'),
    brokerIsCustomer: yup.boolean().required().label('Broker gelijk aan klant'),
    broker: yup.mixed().reference('customer').label('Broker'),
    employee: yup.mixed().reference('employeeOrExternalConsultant').label('Consultant'),
    functionEmployee: yup.string().required().label('Functie consultant'),
    projectDate: yup.array(projectDateSchema).min(1).label('Project datum'),
    currency: yup.string().required('Choose one of the currencies').label('Valuta'),
    hasVat: yup.boolean().required().label('BTW toepassen'),
    sendTo: yup.string().email().label('Facturen naar'),
    paymentTerm: yup.number().integer().min(1).required().label('Betaaltermijn (dagen)'),
    paymentTermWorkdays: yup.boolean().required().label('Betaaltermijn in werkdagen?'),
    enableGAccount: yup.boolean().required().label('G-rekening (wanneer ingesteld) actief?'),
    supplier: yup.mixed().reference('supplier').label('Leverancier/tussenpartij'),
    supplierPaymentTerm: yup.number().integer().label('Betaaltermijn (dagen) leverancier'),
    hasFeePartner: yup.boolean().required().label('Fee afdracht van toepassing'),
    feePartner: yup.mixed().reference('supplier').label('Fee partij'),
    feePaymentTerm: yup.number().integer().label('Betaaltermijn (dagen) fee partij'),
    isArchived: yup.boolean().required().label('Gearchiveerd'),
    files: yup.array(fileSchema),
    notifications: yup.array(notificationSchema),
    // Old fields for migration
    // TODO: remove this after migration
});

type RecordType = InferType<typeof schema>;

const formProperties: FormPropertiesType<RecordType> = {
    projectDate: {
        default: [
            //@ts-ignore
            {
                purchaseRate: 0,
                feeRate: 0,
                hourRate: 0,
                contractHours: 40,
                invoiceSchema: null,
            },
        ],
    },
    id: {
        readonly: true,
    },
    PO: {
        default: 'n.v.t.',
    },
    projectNumber: {
        default: 'n.v.t.',
    },
    broker: {
        hidden: true,
    },
    hasVat: {
        default: true,
    },
    brokerIsCustomer: {
        default: true,
    },
    isArchived: {
        default: false,
    },
    enableGAccount: {
        default: true,
    },
    paymentTerm: {
        default: 14,
    },
    paymentTermWorkdays: {
        default: false,
    },
    driveFolderID: {
        hidden: true,
    },
    currency: {
        radioButtonFieldCurrency: true,
        default: 'EUR',
    },
    language: {
        radioButtonFieldLanguage: true,
        default: 'Nederlands',
    },
    files: {
        hidden: true,
    },
    notifications: {
        hidden: true,
    },
    hasFeePartner: {
        default: false,
    },
    feePartner: {
        hidden: true,
    },

    //Remove
    supplier: {
        hidden: true,
    },
    supplierPaymentTerm: {
        hidden: true,
        default: 0,
    },
    feePaymentTerm: {
        hidden: true,
        default: 0,
    },
};

export const Project: Model<RecordType> = {
    collection: 'projects',
    schema,
    schemaDescription: schema.describe().fields,
    formProperties,
    defaultDisplay: (data) => data.name,
};

export type ProjectType = RecordType;
export type ProjectDateType = InferType<typeof projectDateSchema>;
