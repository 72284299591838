import { action, computed, observable } from 'mobx';

export class ScreenStore {
    @observable screenWidth: number = 0;
    @observable screenHeight: number = 0;
    @action update = (): void => {
        this.screenWidth = window.innerWidth;
        this.screenHeight = window.innerHeight;
    };
    @action init = (): void => {
        this.update();
        window.addEventListener('resize', () => this.update());
    };
    @computed get isMobile(): boolean {
        return this.screenWidth < 768;
    }
}

const store = new ScreenStore();
store.init();
export default store;
