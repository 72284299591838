import { Button, Card } from 'antd';
import DeleteOutlined from '@ant-design/icons/DeleteOutlined';
import PlusOutlined from '@ant-design/icons/PlusOutlined';
import React from 'react';
import { FieldProps } from '.';
import { renderFields } from '../utils/fieldRenderer';
import { ValidationError } from 'yup';

const TableField = ({
    value,
    schemaDescription,
    onChange,
    errors,
    onBlur,
    customFormFields,
    formProperties,
}: FieldProps<Array<Record<string, any>>>): JSX.Element | null => {
    if (!('innerType' in schemaDescription) || schemaDescription.innerType === undefined) {
        return null;
    }
    if (!('fields' in schemaDescription.innerType)) {
        return null;
    }

    const subType = schemaDescription.innerType!.fields!;

    // Dollar is automatically calculated from the other rates.
    if (subType.dollarRate) {
        delete subType.dollarRate;
    }

    // Euro is automatically calculated from the other rates.
    if (subType.euroRate) {
        delete subType.euroRate;
    }

    const handleChange = (index: number) => (fieldName: string, changedVal: any) => {
        value[index][fieldName] = changedVal;
        onChange(value);
    };

    const errorsGroupedPerRow = errors.reduce((acc: ValidationError[][], err) => {
        const matches = err.path.match(/[a-zA-Z]\[(.*)\]/);

        if (matches) {
            const row = Number(matches[1]);
            acc[row] = [...(acc[row] || []), err];
        }
        return acc;
    }, []);

    const fields = (value || []).map((itemValue, index) => {
        return (
            <Card
                key={index}
                title={`${schemaDescription.label} - ${index + 1}`}
                style={{ marginBottom: 10 }}
                actions={[
                    <Button
                        key="delete"
                        danger
                        onClick={() => {
                            value.splice(index, 1);
                            onChange(value);
                        }}
                    >
                        <DeleteOutlined /> Verwijderen
                    </Button>,
                ]}
            >
                {renderFields({
                    currentData: itemValue,
                    errors: errorsGroupedPerRow[index] || [],
                    formProperties: formProperties as any,
                    handleChange: handleChange(index),
                    handleTouchedField: () => onBlur(),
                    isPristine: () => false,
                    originalData: itemValue as any,
                    schemaDescription: subType,
                    customFormFields: customFormFields as any,
                })}
                {errorsGroupedPerRow[index] && (
                    <div className="form--error">{errorsGroupedPerRow[index].map((err) => err.message).join('. ')}</div>
                )}
            </Card>
        );
    });

    return (
        <>
            {fields}
            {((schemaDescription.label === 'Partner' && fields.length === 0) ||
                schemaDescription.label !== 'Partner') && (
                <Button type="primary" shape="circle" onClick={() => onChange([...(value || []), {}])}>
                    <PlusOutlined />
                </Button>
            )}
        </>
    );
};

export default TableField;
