import React from 'react';
import { FieldProps } from '@src/components/Form/fields/index';
import moment from 'moment';
import { Timestamp } from 'firebase/firestore';

const TimestampField = ({ value = Timestamp.now() }: FieldProps<Record<string, any>>): JSX.Element | null => {
    return <span>{moment(value.toDate()).format('YYYY-MM-DD HH:mm')}</span>;
};

export default TimestampField;
