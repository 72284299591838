import { Button, Space, InputRef } from 'antd';
import DeleteOutlined from '@ant-design/icons/DeleteOutlined';
import PlusOutlined from '@ant-design/icons/PlusOutlined';
import React from 'react';
import { FieldProps } from '.';

import { Input } from 'antd';

const StringArrayField = ({ value, onChange, onBlur }: FieldProps<Array<string>>): JSX.Element | null => {
    const handleChange = (index: number, changedVal: any) => {
        value[index] = changedVal;
        return value;
    };
    const fields = (value || []).map((itemValue, index) => {
        const inputRef = React.useRef<InputRef>(null);
        return (
            <Space.Compact style={{ width: '100%', marginBottom: '8px' }} key={index}>
                <Input
                    ref={inputRef}
                    defaultValue={itemValue}
                    onBlur={(e) => {
                        onChange(handleChange(index, e.target.value));
                        onBlur();
                    }}
                />
                <Button
                    key="delete"
                    danger
                    onClick={() => {
                        value.splice(index, 1);
                        onChange(value);
                    }}
                >
                    <DeleteOutlined />
                </Button>
            </Space.Compact>
        );
    });

    return (
        <>
            {fields}
            <Button type="primary" shape="circle" onClick={() => onChange([...(value || []), ''])}>
                <PlusOutlined />
            </Button>
        </>
    );
};

export default StringArrayField;
