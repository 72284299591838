import React, { PropsWithChildren } from 'react';
import { PageHeader, Space } from 'antd';
import { useHistory } from 'react-router';
import SideMenu, { SideMenuProps } from '../SideMenu';

export type DetailLayoutProps = {
    title: string;
    onBack?: () => void;
};

const DetailLayout: React.FC<PropsWithChildren<DetailLayoutProps>> = ({ title, onBack, children }) => {
    const history = useHistory();

    return (
        <Space style={{ width: '100%' }} direction="vertical" size="large">
            <PageHeader onBack={onBack ? onBack : () => history.goBack()} title={title} />
            <div style={{ width: '100%', display: 'flex' }}>{children}</div>
        </Space>
    );
};

export const DetailLayoutContent: React.FC<PropsWithChildren> = ({ children }) => (
    <div style={{ flexGrow: 1 }}>{children}</div>
);

export const DetailLayoutMenu: React.FC<SideMenuProps> = (props) => (
    <div style={{ paddingLeft: 10 }}>
        <SideMenu {...props} />
    </div>
);

export default DetailLayout;
