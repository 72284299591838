import { DeviationType, SalesInvoiceDeviationType } from '@src/models/salesInvoiceDeviation';
import moment from 'moment';
import { WizardState } from '../reducer';

export const getItemsContainingNewDeviations = (items: WizardState['salesInvoice']): WizardState['salesInvoice'] => {
    return items.filter((item) => item.newDeviation && item.newDeviation.amount > 0);
};

export const getItemsContainingSolvedDeviations = (items: WizardState['salesInvoice']): WizardState['salesInvoice'] => {
    return items
        .filter((item) => item.newDeviation)
        .filter((item) => typeof item.solvesDeviation !== 'undefined' && item.solvesDeviation.length > 0);
};

const doDeviationCalculation = (initialAmount: number, amount: number, type: string): number => {
    return type === DeviationType.SHORTAGE ? initialAmount - amount : initialAmount + amount;
};

export const getDeviationBalance = (deviation: SalesInvoiceDeviationType): number => {
    const balance = (deviation.sollution || []).reduce(
        (acc, item) => doDeviationCalculation(acc, item.amount, item.type),
        getInitialAmountForCalculation(deviation),
    );

    return Math.abs(balance);
};

export const getInitialAmountForCalculation = (deviation: SalesInvoiceDeviationType): number => {
    return deviation.type === DeviationType.SHORTAGE ? -deviation.amount : deviation.amount;
};

export const getDeviationBalanceForCalculation = (deviation: SalesInvoiceDeviationType): number => {
    const balance = getDeviationBalance(deviation);
    return deviation.type === DeviationType.SHORTAGE ? -balance : balance;
};

export const getIntermediateDeviationBalance = (
    deviation: SalesInvoiceDeviationType,
    sollutions: SalesInvoiceDeviationType['sollution'],
): number => {
    const balance = sollutions.reduce(
        (acc, item) => doDeviationCalculation(acc, item.amount, item.type),
        getInitialAmountForCalculation(deviation),
    );
    return Math.abs(balance);
};

export const calculateRemainingDeviationBalance = (
    newDeviation: SalesInvoiceDeviationType,
    solvesDeviations: { amount: string; deviation: SalesInvoiceDeviationType }[],
): number => {
    const balance = solvesDeviations.reduce((acc, item) => {
        const parsedAmount = parseFloat(item.amount);
        return !isNaN(parsedAmount) ? doDeviationCalculation(acc, parsedAmount, item.deviation.type) : acc;
    }, getInitialAmountForCalculation(newDeviation));

    return Math.abs(balance);
};

export const getDeviationPeriod = (deviation: SalesInvoiceDeviationType): string => {
    const from = moment(deviation.period.from).format('DD-MM-YYYY');
    const to = moment(deviation.period.to).format('DD-MM-YYYY');

    return `${from} t/m ${to}`;
};

export const getDeviationDescription = (deviation: SalesInvoiceDeviationType): string => {
    const balance = getDeviationBalance(deviation);
    const type =
        deviation.type === DeviationType.SHORTAGE
            ? 'minder gefactureerd dan geschreven'
            : 'meer gefactureerd dan geschreven';

    return `${balance} uur ${type} over ${getDeviationPeriod(deviation)}`;
};
