import { getDeviationBalance } from '@src/components/IncomingInvoiceWizard/utils/invoiceDeviationUtils';
import useDocument from '@src/hooks/useDocument';
import { updateOrCreate } from '@src/misc';
import { DeviationType, SalesInvoiceDeviation } from '@src/models/salesInvoiceDeviation';
import { Button, Input, Modal, Spin, Typography } from 'antd';
import React, { useCallback } from 'react';

export type SolveManuallyModalProps = {
    open: boolean;
    onClose: () => void;
    deviationId: string;
};

export const SolveManuallyModal: React.FC<SolveManuallyModalProps> = ({ open, onClose, deviationId }) => {
    const [deviation] = useDocument(SalesInvoiceDeviation, deviationId);
    const [description, setDescription] = React.useState<string>('');
    const data = deviation?.data();

    const handleInputChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setDescription(e.target.value);
    }, []);

    const handleSave = async () => {
        if (!data) {
            return;
        }

        const remainingBalance = getDeviationBalance(data);

        await updateOrCreate(SalesInvoiceDeviation, {
            ...data,
            sollution: [
                ...(data.sollution || []),
                {
                    amount: remainingBalance,
                    type: data.type === DeviationType.SHORTAGE ? DeviationType.SURPLUS : DeviationType.SHORTAGE,
                    description,
                    invoice: null,
                    manualCorrection: true,
                },
            ],
        });

        onClose();
    };

    return (
        <Modal
            title="Solve deviation manually"
            open={open}
            onCancel={onClose}
            footer={
                <>
                    <Button type="default" shape="round" onClick={onClose}>
                        Annuleren
                    </Button>
                    <Button type="primary" shape="round" onClick={handleSave} disabled={description === ''}>
                        Opslaan
                    </Button>
                </>
            }
            width={800}
        >
            {deviation ? (
                <>
                    <Typography.Paragraph>
                        Je staat op het punt om handmatig deze uurafwijk af te handelen. Beschrijf hieronder hoe deze
                        geconstateerde uurafwijking is opgelost.
                    </Typography.Paragraph>
                    <Input.TextArea value={description} onChange={handleInputChange} />
                </>
            ) : (
                <Spin />
            )}
        </Modal>
    );
};
