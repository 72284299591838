import { InferType } from 'yup';
import * as yup from 'yup';
import { FormPropertiesType } from './types';
import { Model } from '.';
import { fileSchema } from './sub/file';
import { notificationSchema } from './sub/notification';

const schema = yup.object({
    id: yup.string().required().label('ID'),
    firstname: yup.string().required().label('Voornaam'),
    lastname: yup.string().required().label('Achternaam'),
    dateOfBirth: yup.date().required().label('Geboortedatum'),
    email: yup.string().email().required().label('E-mail'),
    bankAcocuntNumber: yup
        .string()
        // .matches(/^[a-zA-Z]{2}\s?[0-9]{2}\s?[a-zA-Z]{4}\s?[0-9]{4}\s?[0-9]{4}\s?[0-9]{2}/, {
        //     message: 'Geen geldige IBAN',
        //     excludeEmptyString: true,
        // })
        // .required()
        .label('Rekeningnummer'),
    bsn: yup
        .string()
        // .length(9, 'BSN te kort/lang')
        // .matches(/^[0-9]*$/, {
        //     message: 'Geen geldig BSN',
        //     excludeEmptyString: true,
        // })
        .label('BSN'),
    contract: yup
        .array(
            yup.object({
                startDate: yup.date().required().label('Start datum'),
                salary: yup.number().positive().required().label('Salaris'),
                hours: yup.number().required().label('Contracturen'),
            }),
        )
        .min(1, 'Voeg een contract toe')
        .label('Contract'),
    employeeNumber: yup.string().required().label('Personeelsnummer'),
    contractHours: yup.number().required().label('Contracturen'),
    isConsultant: yup.boolean().required().label('Is een consultant'),
    street: yup.string().required().label('Straat en huisnummer'),
    postalCode: yup.string().required().label('Postcode'),
    city: yup.string().required().label('Stad'),
    interests: yup.array(yup.string().required()).label('Interesses'),
    relations: yup
        .array(
            yup.object({
                firstname: yup.string().required().label('Voornaam'),
                lastname: yup.string().required().label('Achternaam'),
                dateOfBirth: yup.date().required().label('Geboortedatum'),
                relationType: yup.string().required().label('Type relatie'),
            }),
        )
        .label('Relaties'),
    

    files: yup.array(fileSchema),
    notifications: yup.array(notificationSchema),
    driveFolderID: yup.string(),
    archived: yup.boolean().required().label('Uit dienst'),
});

type RecordType = InferType<typeof schema>;

const formProperties: FormPropertiesType<RecordType> = {
    id: {
        readonly: true,
    },
    archived: {
        default: false,
    },
    isConsultant: {
        default: false,
    },
    files: {
        hidden: true,
    },
    driveFolderID: {
        hidden: true,
    },
    notifications: {
        hidden: true,
    },
};
export const Employee: Model<RecordType> = {
    collection: 'employees',
    schema,
    schemaDescription: schema.describe().fields,
    formProperties,
    defaultDisplay: (data) => data.firstname + ' ' + data.lastname,
};

export type EmployeeType = RecordType;
