import React from 'react';
import { WizardStepProps } from '@src/components/Wizard/Wizard';
import { Form, getDefaultValuesFromProperties } from '@src/components/Form';
import { TimedFileMeta, TimedFileMetaType } from '@src/models/sub/timedFileMeta';

import moment from 'moment';
import { DocumentMetaType, DocumentTypeType } from '@src/models/documentType';

export type DocumentMetaProps = {
    onChange: (meta: TimedFileMetaType) => void;
    documentType: DocumentTypeType;
    value?: TimedFileMetaType;
} & WizardStepProps;

const getSchemaDescriptionForDocumentType = (metaType: DocumentMetaType) => {
    switch (metaType) {
        case DocumentMetaType.DATED:
        case DocumentMetaType.THREE_MONTHLY_EXPIRATION:
        case DocumentMetaType.YEARLY_EXPIRATION:
            return {
                from: TimedFileMeta.schemaDescription.from,
            };

        case DocumentMetaType.CUSTOM_EXPIRATION:
            return {
                from: TimedFileMeta.schemaDescription.from,
                expire: TimedFileMeta.schemaDescription.expire,
            };

        default:
            return TimedFileMeta.schemaDescription;
    }
};

const getAdditionalValues = (values: TimedFileMetaType, metaType: DocumentMetaType): TimedFileMetaType => {
    switch (metaType) {
        case DocumentMetaType.THREE_MONTHLY_EXPIRATION:
            return {
                ...values,
                expire: moment(values.from).add(3, 'months').format('YYYY-MM-DD') as any,
            };

        case DocumentMetaType.YEARLY_EXPIRATION:
            return {
                ...values,
                expire: moment(values.from).add(1, 'year').format('YYYY-MM-DD') as any,
            };
    }

    return values;
};

const DocumentTimedMeta: React.FC<DocumentMetaProps> = ({ onChange, value, onStepComplete, documentType }) => {
    const getMetaFormProperties = () => TimedFileMeta.formProperties;

    const handleChange = (values: TimedFileMetaType) => {
        const newValues = getAdditionalValues(values, documentType.metaType as DocumentMetaType);
        onChange(newValues);
        onStepComplete(true);
    };

    return (
        <Form
            value={
                value ? (value as any) : getDefaultValuesFromProperties<TimedFileMetaType>(TimedFileMeta.formProperties)
            }
            schema={TimedFileMeta.schema}
            onChange={handleChange as any}
            schemaDescription={getSchemaDescriptionForDocumentType(documentType.metaType as DocumentMetaType)}
            getFormProperties={getMetaFormProperties}
        />
    );
};

export default DocumentTimedMeta;
