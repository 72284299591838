import { action, observable } from 'mobx';
import {
    User,
    getAuth,
    GoogleAuthProvider,
    signInWithPopup,
    getRedirectResult,
    signOut,
    onAuthStateChanged,
} from 'firebase/auth';
import { db, firebaseApp } from '@src/services/firebaseService';
import { doc, onSnapshot } from 'firebase/firestore';
import { UserType } from '@src/models/user';

const googleAuthProvider = new GoogleAuthProvider();
const auth = getAuth(firebaseApp);
export let currentUser: UserType | null = null;

export class UserStore {
    @observable loading = true;
    @observable user: null | User = null;
    @observable firestoreUser: null | { [key: string]: any } = null;
    @action login = async (): Promise<void> => {
        await signInWithPopup(auth, googleAuthProvider);
    };
    @action init = async (): Promise<void> => {
        let user = auth.currentUser;

        if (!user) {
            const result = await getRedirectResult(auth);
            user = result?.user || null;
        }
        if (user) {
            const userDoc = doc(db, 'users', user.uid);
            onSnapshot(
                userDoc,
                (fsUserDoc) => {
                    this.firestoreUser = fsUserDoc.data() || null;
                    currentUser = fsUserDoc.data() as UserType;
                },
                (error) => {
                    console.log('error getting user doc', error);
                    this.firestoreUser = null;
                },
            );
            this.user = user;
        }
        this.loading = false;
    };

    @action logout = (): void => {
        signOut(auth);
        this.user = null;
        this.firestoreUser = null;
    };
}

const store = new UserStore();

onAuthStateChanged(auth, store.init);
export default store;
