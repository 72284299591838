import { Table, Descriptions, Divider } from 'antd';
import React from 'react';
import { SchemaDescription, SchemaFieldDescription } from 'yup';
import { ValueRenderer } from './renderers/valueRenderers';

export type ValueRenderers = {
    [key: string]: ValueRenderer;
};

type DataDisplayPropsList<T extends { [key: string]: any }> = {
    display: 'list';
    value: T;
};

type DataDisplayPropsTable<T extends { [key: string]: any }> = {
    display: 'table';
    value: T[];
};

export type DataDisplayProps<T extends { [key: string]: any }> = {
    schemaDescription: Record<string, SchemaFieldDescription> | any;
    displayFields: string[];
    fieldLabels?: { [key: string]: string };
    title?: string;
    valueRenderer?: ValueRenderers;
    copyable?: boolean;
    bordered?: boolean;
    style?: React.CSSProperties;
} & (DataDisplayPropsList<T> | DataDisplayPropsTable<T>);

export const DataDisplay = <T extends { [key: string]: any }>({
    schemaDescription,
    displayFields,
    value,
    valueRenderer,
    display,
    title,
    style,
    copyable = false,
    bordered = false,
    fieldLabels = {},
}: DataDisplayProps<T>): JSX.Element => {
    const defaultRenderer: ValueRenderer = (value: any) => {
        return typeof value === 'string' ? value : value?.toString() || 'Could not be displayed';
    };

    const schemaFields =
        typeof schemaDescription.type !== 'undefined' &&
        schemaDescription.type === 'array' &&
        schemaDescription.innerType
            ? schemaDescription.innerType.fields
            : schemaDescription;

    const schemaKeys = Object.keys(schemaFields);

    const fields = displayFields.filter((fieldName) => schemaKeys.includes(fieldName));

    return display === 'list' ? (
        <>
            <Descriptions
                title={title}
                colon
                layout="horizontal"
                extra={copyable ? <span>test</span> : undefined}
                labelStyle={{ fontWeight: 'bold' }}
                column={2}
            >
                {fields.map((fieldName) => {
                    const renderer =
                        valueRenderer && (valueRenderer[fieldName] || valueRenderer.default)
                            ? valueRenderer[fieldName] || valueRenderer.default
                            : defaultRenderer;
                    return (
                        <Descriptions.Item
                            key={fieldName}
                            label={fieldLabels[fieldName] || (schemaFields[fieldName] as SchemaDescription).label}
                        >
                            {value[fieldName] !== null && value[fieldName] !== undefined
                                ? renderer(value[fieldName], value)
                                : 'leeg'}
                        </Descriptions.Item>
                    );
                })}
            </Descriptions>
            {bordered && <Divider />}
        </>
    ) : (
        <Table
            style={style}
            columns={fields.map((fieldName) => ({
                title: fieldLabels[fieldName] || (schemaFields[fieldName] as SchemaDescription).label,
                dataIndex: fieldName,
                key: fieldName,
            }))}
            pagination={false}
            dataSource={value.map((item, index) =>
                fields.reduce(
                    (acc, fieldName) => {
                        const renderer =
                            valueRenderer && valueRenderer[fieldName] ? valueRenderer[fieldName] : defaultRenderer;
                        return { ...acc, [fieldName]: renderer(item[fieldName], item) };
                    },
                    { key: index },
                ),
            )}
        />
    );
};
