import React from 'react';
import GoogleOutlined from '@ant-design/icons/GoogleOutlined';
import { Button } from 'antd';

interface SignInProps {
    onClick: () => any;
    disabled?: boolean;
}

const SignInButton = ({ onClick, disabled }: SignInProps): JSX.Element => (
    <Button onClick={onClick} disabled={disabled} type="primary" icon={<GoogleOutlined />} size="middle">
        Login with Google
    </Button>
);

export default SignInButton;
