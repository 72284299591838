import * as yup from 'yup';
import { FormPropertiesType } from './types';
import { User } from './user';
import { Employee } from './employee';
import { Customer } from './customer';
import { Project } from './project';
import { SalesInvoice } from './salesInvoice';
import { Settings } from './settings';
import { Assessment } from './assessment';

export interface Model<T extends Record<string, any>> {
    collection: string;
    schema: yup.ObjectSchema<T>;
    schemaDescription: Record<keyof T, yup.SchemaFieldDescription>;
    formProperties: FormPropertiesType<T>;
    defaultDisplay: (data: T) => string;
}

export type RecordType<T> = T extends Model<infer X> ? X : never;

const Models: Record<string, Model<any>> = {
    user: User,
    employee: Employee,
    customer: Customer,
    project: Project,
    rating: Assessment,
    invoice: SalesInvoice,
    settings: Settings,
};

export default Models;
