import React from 'react';

import EditOutlined from '@ant-design/icons/EditOutlined';
import UploadOutlined from '@ant-design/icons/UploadOutlined';
import FileTextOutlined from '@ant-design/icons/FileTextOutlined';

import { FolderOutlined } from '@ant-design/icons';
import { Button, Card, List } from 'antd';

export type SideMenuItem = {
    label: string;
    onClick: () => void;
    disabled?: boolean;
} & (
    | {
          type: 'edit' | 'upload' | 'folder' | 'note';
          icon?: React.ReactElement;
      }
    | { type: 'custom'; icon: React.ReactElement }
);

export type SideMenuProps = {
    items: SideMenuItem[];
};

const getItemIcon = (item: SideMenuItem): React.ReactElement => {
    if (item.icon) {
        return item.icon;
    }

    switch (item.type) {
        case 'edit':
            return <EditOutlined />;

        case 'upload':
            return <UploadOutlined />;

        case 'folder':
            return <FolderOutlined />;

        case 'note':
            return <FileTextOutlined />;

        case 'custom':
            return item.icon;
    }
};

const SideMenu: React.FC<SideMenuProps> = ({ items }) => {
    return (
        <Card title="Acties">
            <List style={{ width: 250 }}>
                {items.map((item) => (
                    <List.Item key={item.label}>
                        <Button type="link" disabled={item.disabled} onClick={item.onClick}>
                            {getItemIcon(item)}
                            <span>{item.label}</span>
                        </Button>
                    </List.Item>
                ))}
            </List>
        </Card>
    );
};

export default SideMenu;
