import { InferType } from 'yup';
import * as yup from 'yup';
import { FormPropertiesType } from '../types';

export const fileSchema = yup.object({
    id: yup.string().label('ID'),
    mimeType: yup.string().label('Mimetype'),
    fileName: yup.string().label('Naam'),
    documentType: yup.mixed().label('Document type'),
    meta: yup.mixed(),
    archived: yup.boolean().label('Gearchiveerd'),
    // createdOn: yup.date().label('Aangemaakt op'),
});

type RecordType = InferType<typeof fileSchema>;

export const formProperties: FormPropertiesType<RecordType> = {
    id: {
        readonly: true,
    },
    meta: {
        hidden: true,
    },
};

export type FileType = RecordType;
