import * as yup from 'yup';
import { Model } from '.';
import { FormPropertiesType } from './types';

export enum DeviationType {
    SURPLUS = 'SURPLUS',
    SHORTAGE = 'SHORTAGE',
}

export const schema = yup.object({
    id: yup.string().label('ID'),
    project: yup.mixed().label('Project'),
    invoice: yup.mixed().label('Factuur'),
    period: yup
        .object({
            from: yup.mixed().label('Van'),
            to: yup.mixed().label('Tot'),
        })
        .label('Factuurperiode'),
    amount: yup.number().required().label('Aantal uren afwijkend'),
    type: yup.string().oneOf([DeviationType.SURPLUS, DeviationType.SHORTAGE]).required().label('Type afwijking'),
    state: yup.string().oneOf(['OPEN', 'SOLVED', 'CLOSED']).label('Status'),
    sollution: yup.array().of(
        yup
            .object({
                amount: yup.number().label('Aantal uren oplossing'),
                description: yup.string().label('Omschrijving'),
                invoice: yup.mixed().nullable().label('Factuur'),
                type: yup.string().oneOf([DeviationType.SURPLUS, DeviationType.SHORTAGE]).label('Type oplossing'),
                manualCorrection: yup.boolean().label('Handmatige correctie'),
            })
            .label('Oplossing'),
    ),
});

type RecordType = yup.InferType<typeof schema>;

const formProperties: FormPropertiesType<RecordType> = {
    id: {
        readonly: true,
        hidden: true,
    },
    state: {
        hidden: true,
    },
    project: {
        hidden: true,
    },
    invoice: {
        hidden: true,
    },
    period: {
        hidden: true,
    },
    sollution: {
        hidden: true,
    },
    type: {
        options: [
            { value: DeviationType.SURPLUS, label: 'Meer uren op de factuur dan in Clockify' },
            { value: DeviationType.SHORTAGE, label: 'Minder uren op de factuur dan in Clockify' },
        ],
    },
};
export const SalesInvoiceDeviation: Model<RecordType> = {
    collection: 'invoices_deviations',
    schema,
    schemaDescription: schema.describe().fields,
    formProperties,
    defaultDisplay: (data) => data.id,
};

export type SalesInvoiceDeviationType = RecordType;
