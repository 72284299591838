import { useEffect, useState } from 'react';
import { collection, getDocs, query, QueryConstraint } from 'firebase/firestore';
import { db } from '@src/services/firebaseService';
import { Model } from '@src/models';
import { NotificationType } from '@src/models/sub/notification';

const useNotificationCount = <T extends { notifications: NotificationType[] }>(
    model: Model<T>,
    where?: QueryConstraint,
): number => {
    const [count, setCount] = useState<number>(0);

    async function getCount() {
        const q = query(collection(db, model.collection), ...(where ? [where] : []));
        const result = await getDocs(q);

        const count = result.docs.reduce((acc, cur) => {
            const data = cur.data() as T;
            const highNotifications = data?.notifications?.filter((notification) => notification.severity === 'HIGH');
            return acc + (highNotifications?.length || 0);
        }, 0);

        setCount(count);
    }

    useEffect(() => {
        getCount();
    }, []);

    return count;
};

export default useNotificationCount;
