import React from 'react';
import Menu, { MenuProps } from 'antd/lib/menu';
import Badge from 'antd/lib/badge';
import { useHistory } from 'react-router-dom';

import TeamOutlined from '@ant-design/icons/TeamOutlined';
import SolutionOutlined from '@ant-design/icons/SolutionOutlined';
import HomeOutlined from '@ant-design/icons/HomeOutlined';
import RocketOutlined from '@ant-design/icons/RocketOutlined';
import CalculatorOutlined from '@ant-design/icons/CalculatorOutlined';
import SmileOutlined from '@ant-design/icons/SmileOutlined';
import ExceptionOutlined from '@ant-design/icons/ExceptionOutlined';
import FileOutlined from '@ant-design/icons/FileOutlined';
import EuroOutlined from '@ant-design/icons/EuroOutlined';
import CalendarOutlined from '@ant-design/icons/CalendarOutlined';
import SettingOutlined from '@ant-design/icons/SettingOutlined';
import MessageOutlined from '@ant-design/icons/MailOutlined';
import { observer } from 'mobx-react';
import DashboardOutlined from '@ant-design/icons/lib/icons/DashboardOutlined';
import useCount from '@src/hooks/useCount';
import { IncomingEmail } from '@src/models/incomingEmail';
import { where } from 'firebase/firestore';
import { filterOnCapability } from '@src/utils/capabilities';
import { Offer } from '@src/models/offer';
import useNotificationCount from '@src/hooks/useNotificationCount';
import { Employee } from '@src/models/employee';
import { Project } from '@src/models/project';
import { Supplier } from '@src/models/supplier';
import { ExternalConsultant } from '@src/models/externalConsultant';
import { useDocumentNotificationsCount } from '@src/hooks/useDocumentsNotificationCount';
import { SalesInvoiceDeviation } from '@src/models/salesInvoiceDeviation';
import { SalesInvoice } from '@src/models/salesInvoice';

/* Don't use SubMenu, it is broken in the production build.. */
const whereCondition = where('status', '==', 'NEW');
const employeeNotificationWhere = where('archived', '!=', true);
const projectNotificationWhere = where('isArchived', '!=', true);

const Sidebar: React.FunctionComponent = () => {
    const numNewMessages = useCount(IncomingEmail, whereCondition);
    const employeeNotificationCount = useNotificationCount(Employee, employeeNotificationWhere);
    const projectNotificationCount = useNotificationCount(Project, projectNotificationWhere);
    const supplierNotificationCount = useNotificationCount(Supplier);
    const externalConsultantNotificationCount = useNotificationCount(ExternalConsultant);
    const documentsNotificationCount = useDocumentNotificationsCount();
    const history = useHistory();

    const salesChildren = filterOnCapability([
        [
            'readProjects',
            {
                icon: <HomeOutlined />,
                label: 'Dashboard',
                onClick: () => history.push('/projects'),
                key: 'projecten',
            },
        ],
        [
            'readProjects',
            {
                icon: <RocketOutlined />,
                label: (
                    <Badge offset={[10, 0]} count={projectNotificationCount}>
                        Projecten
                    </Badge>
                ),
                onClick: () => history.push('/projects/overview'),
                key: 'projecten-overview',
            },
        ],
        [
            'readInvoices',
            {
                icon: <EuroOutlined />,
                label: 'Facturen',
                onClick: () => history.push(`/${SalesInvoice.collection}`),
                key: 'sales-invoices',
            },
        ],
        [
            'readInvoices',
            {
                icon: <ExceptionOutlined />,
                label: 'Uurafwijkingen',
                onClick: () => history.push(`/${SalesInvoiceDeviation.collection}`),
                key: SalesInvoiceDeviation.collection,
            },
        ],
        [
            'readInvoices',
            {
                icon: <CalendarOutlined />,
                label: "Facturatieschema's",
                onClick: () => history.push('/invoice-schema'),
                key: 'invoice-schema',
            },
        ],
        [
            'readInvoices',
            {
                icon: <DashboardOutlined />,
                label: 'Sales dashboard',
                onClick: () => history.push('/sales-dashboard'),
                key: 'sales-dashboard',
            },
        ],
    ]);

    const hrChildren = filterOnCapability([
        [
            'readEmployees',
            {
                icon: <HomeOutlined />,
                label: 'Dashboard',
                onClick: () => history.push('/employees'),
                key: 'employees',
            },
        ],
        [
            'readEmployees',
            {
                icon: <SmileOutlined />,
                label: (
                    <Badge offset={[10, 0]} count={employeeNotificationCount}>
                        Personeel
                    </Badge>
                ),
                onClick: () => history.push('/employees/overview'),
                key: 'employees-overview',
            },
        ],
        [
            'readOffers',
            {
                icon: <RocketOutlined />,
                label: 'Aanbiedingen',
                onClick: () => history.push(`/${Offer.collection}`),
                key: Offer.collection,
            },
        ],
        [
            'readOffers',
            {
                icon: <CalculatorOutlined />,
                label: 'Looncalculator',
                onClick: () => history.push(`/salary_calculator`),
                key: 'salary_calculator',
            },
        ],
        [
            'readEmployees',
            {
                icon: <SolutionOutlined />,
                label: 'Beoordelingsformulieren',
                onClick: () => history.push('/assessments/overview'),
                key: 'assessments',
            },
        ],
    ]);

    const relationChildren = filterOnCapability([
        [
            'readRelations',
            {
                icon: <SolutionOutlined />,
                label: (
                    <Badge offset={[10, 0]} count={supplierNotificationCount}>
                        Leveranciers
                    </Badge>
                ),
                onClick: () => history.push('/suppliers'),
                key: 'suppliers',
            },
        ],
        [
            'readRelations',
            {
                icon: <SolutionOutlined />,
                label: 'Klanten',
                onClick: () => history.push('/customers'),
                key: 'customers',
            },
        ],
        [
            'readRelations',
            {
                icon: <SmileOutlined />,
                label: (
                    <Badge offset={[10, 0]} count={externalConsultantNotificationCount}>
                        Externe consultants
                    </Badge>
                ),
                onClick: () => history.push('/external_consultants'),
                key: 'external_consultants',
            },
        ],
    ]);

    const settingsChildren = filterOnCapability([
        [
            'userManagement',
            {
                icon: <TeamOutlined />,
                label: 'Gebruikers',
                onClick: () => history.push('/users'),
                key: 'users',
            },
        ],
        [
            'settingsManagement',
            {
                icon: <FileOutlined />,
                label: 'Document types',
                onClick: () => history.push('/settings/document-types'),
                key: 'document-types',
            },
        ],
        [
            'settingsManagement',
            {
                icon: <SettingOutlined />,
                label: 'Instellingen',
                onClick: () => history.push('/settings'),
                key: 'settings',
            },
        ],
    ]);

    const menuItems: MenuProps['items'] = [
        {
            icon: <HomeOutlined />,
            label: 'Dashboard',
            onClick: () => history.push('/'),
            key: 'dashboard',
        },
        {
            icon: <MessageOutlined />,
            label: (
                <Badge offset={[10, 0]} count={numNewMessages}>
                    Inbox
                </Badge>
            ),
            onClick: () => history.push('/inbox'),
            key: 'inbox',
        },
        ...filterOnCapability([
            [
                'readProjects',
                {
                    icon: <FileOutlined />,
                    label: (
                        <Badge offset={[10, 0]} count={documentsNotificationCount}>
                            Documenten
                        </Badge>
                    ),
                    onClick: () => history.push('/documents'),
                    key: 'documents',
                },
            ],
        ]),
        {
            type: 'divider',
        },
        {
            type: 'group',
            label: 'Relaties',
            children: relationChildren,
        },
        {
            type: 'divider',
        },
        {
            type: 'group',
            label: 'HR',
            children: hrChildren,
        },
        {
            type: 'divider',
        },
        {
            type: 'group',
            label: 'Verkoop',
            children: salesChildren,
        },
        {
            type: 'divider',
        },
        {
            type: 'group',
            label: 'Instellingen',
            children: settingsChildren,
        },
    ];

    return (
        <Menu
            mode="inline"
            defaultSelectedKeys={[window.location.pathname.substr(1).replace('/', '-') || 'dashboard']}
            items={menuItems}
        />
    );
};

export default observer(Sidebar);
