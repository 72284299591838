import { useEffect, useState } from 'react';
import firestore, { doc, onSnapshot } from 'firebase/firestore';
import { Model } from '@src/models';
import { db } from '@src/services/firebaseService';

type OriginalValues<T> = { snapshot: firestore.DocumentSnapshot<T>; doc: firestore.DocumentReference<T> };

const useDocument = <T extends Record<string, unknown>>(
    model: Model<T>,
    id: string,
): [firestore.DocumentSnapshot<T> | null, OriginalValues<T>] => {
    const [item, setItem] = useState<null | OriginalValues<T>>({} as any);

    useEffect(() => {
        if (id) {
            const docRef = doc(db, model.collection, id);

            return onSnapshot(
                docRef,
                (snapshot) => {
                    setItem({ doc: docRef, snapshot } as any);
                },
                (err) => {
                    console.log(`Encountered error: ${err}`);
                },
            );
        }
    }, [model, id]);

    return [(item?.snapshot as any) || null, item as any];
};

export default useDocument;
