import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import { DEPLOYMENT_MODE, settings } from '@src/settings';
import { getFirestore, collection, getDocs, doc, getDoc } from 'firebase/firestore';

export const firebaseProductionConfig = {
    apiKey: 'AIzaSyBacuZLTcp8dhV-1C6J_KySF9ZXkKtzeGA',
    authDomain: 'facturatie-1dc8d.firebaseapp.com',
    // authDomain: "localhost",
    databaseURL: 'https://facturatie-1dc8d.firebaseio.com',
    projectId: 'facturatie-1dc8d',
    storageBucket: 'facturatie-1dc8d.appspot.com',
    messagingSenderId: '638954512775',
    appId: '1:638954512775:web:9b894f6bb982977741f322',
};

export const firebaseStagingConfig = {
    apiKey: 'AIzaSyC-j6yVg8baElcJRivxozb1LqmCg1bRVIA',
    authDomain: 'facturatie-staging.firebaseapp.com',
    projectId: 'facturatie-staging',
    storageBucket: 'facturatie-staging.appspot.com',
    messagingSenderId: '362599152418',
    appId: '1:362599152418:web:003fd9d7293389ac36037b',
    databaseURL: 'https://facturatie-staging.firebaseio.com',
};

export const firebaseConfig =
    settings.deploymentMode === DEPLOYMENT_MODE.PRODUCTION ? firebaseProductionConfig : firebaseStagingConfig;

export const firebaseApp = firebase.initializeApp(firebaseConfig);

export const db = getFirestore(firebaseApp);

export const firestoreDoc = async (collection: string, path: string) => {
    const docRef = doc(db, collection, path);
    const docSnap = await getDoc(docRef);

    return docSnap;
};

export const firestoreDocs = async (path: string) => {
    const collectionRef = collection(db, path);
    const docsSnap = await getDocs(collectionRef);

    return docsSnap;
};
