import React from 'react';
import { FieldProps } from '@src/components/Form/fields/index';
import { renderFields } from '../utils/fieldRenderer';

const ObjectField = ({
    value = {},
    schemaDescription,
    errors,
    onChange,
    onBlur,
    formProperties,
    customFormFields,
}: FieldProps<Record<string, any>>): JSX.Element | null => {
    if (!('fields' in schemaDescription)) {
        return null;
    }

    const subType = schemaDescription.fields!;

    const handleChange = (fieldName: string, fieldValue: any) => {
        onChange({ ...value, [fieldName]: fieldValue });
    };

    return (
        <>
            {renderFields({
                currentData: value,
                errors: errors || [],
                formProperties: formProperties as any,
                handleChange: handleChange,
                handleTouchedField: onBlur,
                isPristine: () => false,
                originalData: value as any,
                schemaDescription: subType,
                customFormFields: customFormFields as any,
            })}
        </>
    );
};

export default ObjectField;
