import React from 'react';
import { Radio } from 'antd';
import { FieldProps } from '..';
import './radioButtonField.css';

const RadioButtonFieldCurrency = ({ value, onChange, readonly }: FieldProps<boolean>): JSX.Element => {
    return (
        <Radio.Group buttonStyle="solid" disabled={readonly} onChange={(e) => onChange(e.target.value)} value={value}>
            <Radio.Button value="EUR">Euro</Radio.Button>
            <Radio.Button value="USD">Dollar</Radio.Button>
        </Radio.Group>
    );
};

export default RadioButtonFieldCurrency;
