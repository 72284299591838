import React from 'react';
import { Select } from 'antd';
import { FieldProps } from '@src/components/Form/fields';

export default function TextSelectField({
    formProperty,
    value,
    readonly,
    onBlur,
    onChange,
}: FieldProps<any>): JSX.Element {
    const options = formProperty?.options ?? [];

    return (
        <Select
            options={options.map((option) => (typeof option === 'string' ? { label: option, value: option } : option))}
            style={{ width: '100%' }}
            disabled={readonly}
            value={value?.id || value}
            onBlur={onBlur}
            onChange={onChange}
        />
    );
}
