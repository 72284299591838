import React from 'react';
import { RouteComponentProps } from 'react-router';
import { Capabilitiy, userHasCapability } from './capabilities';

export const guardedRoute =
    (capability: Capabilitiy, Component: React.ComponentType<any>) => (props: RouteComponentProps<any>) => {
        if (userHasCapability(capability)) {
            return <Component {...props} />;
        } else {
            return <>Not allowed</>;
        }
    };
