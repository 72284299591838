import React from 'react';
import { Radio } from 'antd';
import './radioButtonField.css';
import { FieldProps } from '..';
import NL_FLAG from '@src/images/NL-flag.png';
import UK_FLAG from '@src/images/UK-flag.png';

const RadioButtonFieldLanguage = ({ value, onChange, readonly }: FieldProps<boolean>): JSX.Element => {
    return (
        <Radio.Group buttonStyle="solid" disabled={readonly} onChange={(e) => onChange(e.target.value)} value={value}>
            <Radio value="Nederlands">
                <img style={{ width: '2.5rem', height: '2.5rem' }} src={NL_FLAG} alt="Nederlandse vlag" />
            </Radio>
            <Radio value="Engels">
                <img style={{ width: '2.5rem', height: '2.5rem' }} src={UK_FLAG} alt="Engelse vlag" />
            </Radio>
        </Radio.Group>
    );
};

export default RadioButtonFieldLanguage;
