import firebaseProject from '../firebaseProject.json';

export enum DEPLOYMENT_MODE {
    UNKNOWN,
    STAGING,
    PRODUCTION,
}

export const getDeploymentMode = (firebaseProject: string): DEPLOYMENT_MODE => {
    switch (firebaseProject) {
        case 'facturatie-1dc8d':
            return DEPLOYMENT_MODE.PRODUCTION;

        case 'facturatie-staging':
            return DEPLOYMENT_MODE.STAGING;

        default:
            return DEPLOYMENT_MODE.STAGING;
    }
};

export const settings = {
    deploymentMode: getDeploymentMode(firebaseProject),
};
