import React from 'react';
import { Radio } from 'antd';
import './radioButtonField.css';
import { FieldProps } from '..';
import { SUPPLIER_TYPE } from '@src/sharedConstants/supplierType';

const RadioButtonFieldSupplierType = ({ value, onChange, readonly }: FieldProps<boolean>): JSX.Element => {
    return (
        <Radio.Group buttonStyle="solid" disabled={readonly} onChange={(e) => onChange(e.target.value)} value={value}>
            {Object.entries(SUPPLIER_TYPE).map(([key, value]) => (
                <Radio.Button key={key} value={key}>
                    {value}
                </Radio.Button>
            ))}
        </Radio.Group>
    );
};

export default RadioButtonFieldSupplierType;
