import { SalesInvoiceDeviationType } from '@src/models/salesInvoiceDeviation';
import { Tag } from 'antd';
import React from 'react';

export type SalesInvoiceDeviationTagProps = {
    state: SalesInvoiceDeviationType['state'];
};

export const SalesInvoiceDeviationTag: React.FC<SalesInvoiceDeviationTagProps> = ({ state }) =>
    state === 'OPEN' ? <Tag color="red">Open</Tag> : <Tag color="green">Afgehandeld</Tag>;
