import * as yup from 'yup';
import { InferType } from 'yup';
import { FormPropertiesType } from './types';
import { Model } from './index';
import { generatedDocumentSchema } from './sub/generatedDocument';

export const schema = yup.object({
    id: yup.string().label('ID'),
    firstname: yup.string().required().label('Voornaam'),
    lastname: yup.string().required().label('Achternaam'),
    dateOfBirth: yup.date().required().label('Geboortedatum'),
    placeOfBirth: yup.string().required().label('Geboorteplaats'),
    email: yup.string().email().required().label('E-mail'),
    startDate: yup.date().required().label('Start datum'),
    fixedTime: yup.boolean().required().default(false).label('Bepaalde tijd'),
    endDate: yup.date().notRequired().when('fixedTime', { is: true, then: yup.date().required() }).label('Einddatum'),
    salary: yup.number().positive().required().label('Salaris'),
    contractHours: yup.number().required().label('Contracturen'),
    street: yup.string().required().label('Straat en huisnummer'),
    postalCode: yup.string().required().label('Postcode'),
    city: yup.string().required().label('Stad'),
    personalIdentificationNumber: yup.string().required().label('BSN nummer'),
    contractType: yup.string().required().oneOf(['CONSULTANT', 'OVERHEAD']).label('Contractvariant'),
    employmentType: yup.string().required().oneOf(['PERMANENT', 'TEMPORARY']).label('Contract type'),
    contractLength: yup.number().required().label('Contract lengte'),
    driveFolderID: yup.string(),
    archived: yup.boolean().label('Geachriveerd'),
    documents: yup.array(generatedDocumentSchema),
});

type RecordType = InferType<typeof schema>;

const formProperties: FormPropertiesType<RecordType> = {
    id: {
        readonly: true,
    },
    archived: {
        hidden: true,
        default: false,
    },
    driveFolderID: {
        hidden: true,
    },
    contractType: {
        hidden: true,
        default: 'CONSULTANT',
    },
    employmentType: {
        hidden: true,
        default: 'PERMANENT',
    },
    fixedTime: {
        default: false,
    },
    contractLength: {
        hidden: true,
        default: 0,
    },
    documents: {
        hidden: true,
    },
};

export const Offer: Model<RecordType> = {
    collection: 'offers',
    schema,
    schemaDescription: schema.describe().fields,
    formProperties,
    defaultDisplay: (data) => `${data.firstname} ${data.lastname}`,
};

export type OfferType = RecordType;
