import { Model } from '@src/models';
import { Spin } from 'antd';
import React from 'react';
import { NavLink } from 'react-router-dom';
import LinkOutlined from '@ant-design/icons/LinkOutlined';
import useDocument from '@src/hooks/useDocument';

export type RecordLinkProps = {
    model: Model<any>;
    id: string;
    clickable?: boolean;
    style?: { [key: string]: any };
};

const RecordLink: React.FC<RecordLinkProps> = ({ model, id, style = {}, clickable = true }) => {
    const [doc] = useDocument(model, id);
    const data = doc?.data();

    const path = `/${model.collection}/${data?.id}`;

    return data ? (
        clickable ? (
            <NavLink to={`${path}`} style={{ textDecoration: 'underline', ...style }}>
                <LinkOutlined style={{ marginRight: 5 }} />
                {model.defaultDisplay(data)}
            </NavLink>
        ) : (
            <>{model.defaultDisplay(data)}</>
        )
    ) : (
        <Spin />
    );
};

export default RecordLink;
