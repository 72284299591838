import { useEffect, useState } from 'react';
import {
    collection,
    onSnapshot,
    query,
    QueryCompositeFilterConstraint,
    QueryConstraint,
    QueryNonFilterConstraint,
} from 'firebase/firestore';

import { Model } from '@src/models';
import { db } from '@src/services/firebaseService';

function useCollection<T extends Record<string, unknown>>(model: Model<T>, ...queryConstraints: QueryConstraint[]): T[];
function useCollection<T extends Record<string, unknown>>(
    model: Model<T>,
    contraints?: QueryCompositeFilterConstraint | QueryConstraint,
    ...nonFilterConstraints: QueryNonFilterConstraint[]
): T[];
function useCollection<T extends Record<string, unknown>>(model: Model<T>, ...contraints: any[]): T[] {
    const [items, setItems] = useState<T[]>([]);

    useEffect(() => {
        const q = contraints
            ? query(collection(db, model.collection), ...contraints)
            : collection(db, model.collection);

        return onSnapshot(
            q,
            (querySnapshot) => {
                setItems(querySnapshot.docs.map<T>((doc) => doc.data() as T));
            },
            (err) => {
                console.log(`Encountered error: ${err}`);
            },
        );
    }, [...contraints]);

    return items;
}

export default useCollection;
