import React from 'react';
import { Sidebar } from '@src/components';
import { Layout as AntdLayout } from 'antd';
import Header from '@src/components/Header';
import screenStore from '@src/stores/Screen';

const { Sider, Content } = AntdLayout;

const Layout: React.FC<React.PropsWithChildren> = ({ children }) => (
    <AntdLayout style={{ height: '100%' }}>
        <AntdLayout.Header>
            <Header />
        </AntdLayout.Header>
        <AntdLayout>
            <Sider collapsible defaultCollapsed={screenStore.isMobile}>
                <Sidebar />
            </Sider>
            <AntdLayout style={{ padding: '24px' }}>
                <Content
                    style={{
                        padding: 24,
                        margin: 0,
                        flex: 'none',
                    }}
                >
                    {children}
                </Content>
            </AntdLayout>
        </AntdLayout>
    </AntdLayout>
);

export default Layout;
