import { useEffect, useState } from 'react';
import { Model } from '@src/models';
import { collection, getCountFromServer, query, QueryConstraint } from 'firebase/firestore';
import { db } from '@src/services/firebaseService';

const useCount = <T extends Record<string, unknown>>(model: Model<T>, where: QueryConstraint): number => {
    const [count, setCount] = useState<number>(0);

    async function getCount() {
        const q = query(collection(db, model.collection), where);
        const result = await getCountFromServer(q);
        setCount(result.data().count);
    }

    useEffect(() => {
        getCount();
    }, []);

    return count;
};

export default useCount;
