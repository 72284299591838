import React, { useMemo } from 'react';
import { ColumnsType } from 'antd/lib/table';
import { fieldLabel } from '@src/misc';
import useCollection from '@src/hooks/useCollection';
import useDocument from '@src/hooks/useDocument';
import CollectionTable from '@src/components/CollectionTable';
import InvoiceDeviationActions from './actions';
import { SalesInvoice } from '@src/models/salesInvoice';
import { Project } from '@src/models/project';
import moment from 'moment';
import { Col, PageHeader, Row, Space, Switch } from 'antd';
import { DeviationType, SalesInvoiceDeviation, SalesInvoiceDeviationType } from '@src/models/salesInvoiceDeviation';
import { getDeviationBalance } from '@src/components/IncomingInvoiceWizard/utils/invoiceDeviationUtils';
import { SalesInvoiceDeviationTag } from './components/SalesInvoiceDeviationTag';
import { where } from 'firebase/firestore';

const ProjectDisplay = ({ projectID }: { projectID: string }) => {
    const [item] = useDocument(Project, projectID);
    if (!item) {
        return null;
    }
    const data = item.data();
    if (!data) {
        return null;
    }
    return <span>{data.name || ''}</span>;
};

const columns: ColumnsType<SalesInvoiceDeviationType> = [
    {
        title: fieldLabel(SalesInvoice.schemaDescription, 'project'),
        dataIndex: 'project',
        key: 'project',
        render: (project) => {
            if (!project || !project.id) {
                return null;
            }
            return <ProjectDisplay projectID={project.id} />;
        },
    },
    {
        title: fieldLabel(SalesInvoiceDeviation.schemaDescription, 'period'),
        dataIndex: 'period',
        key: 'period',
        render: ({ from, to }) => moment(from).format('DD-MM-YYYY') + ' - ' + moment(to).format('DD-MM-YYYY'),
    },
    {
        title: fieldLabel(SalesInvoiceDeviation.schemaDescription, 'type'),
        dataIndex: 'type',
        key: 'type',
        render: (type) => (type === DeviationType.SHORTAGE ? 'Tekort' : 'Overschot'),
    },
    {
        title: fieldLabel(SalesInvoiceDeviation.schemaDescription, 'amount'),
        dataIndex: 'amount',
        key: 'amount',
        render: (amount) => amount,
    },
    {
        title: 'Saldo afwijking',
        dataIndex: 'amount',
        key: 'amount',
        render: (amount, record) => getDeviationBalance(record).toString(),
    },
    {
        title: fieldLabel(SalesInvoiceDeviation.schemaDescription, 'state'),
        dataIndex: 'state',
        key: 'state',
        render: (state) => <SalesInvoiceDeviationTag state={state} />,
    },
    {
        title: 'Actie',
        dataIndex: 'id',
        key: 'x',
        render: (_, record) => <InvoiceDeviationActions id={record.id} />,
    },
];

const SalesInvoiceDeviations = (): JSX.Element => {
    const [showArchived, setShowArchived] = React.useState(false);
    const whereMemo = useMemo(() => (showArchived ? undefined : where('state', '==', 'OPEN')), [showArchived]);
    const items = useCollection(SalesInvoiceDeviation, whereMemo);

    return (
        <>
            <Space style={{ width: '100%' }} direction="vertical" size="large">
                <PageHeader title="Uurafwijkingen" />
                <Space style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Row>
                        <Col style={{ marginRight: '1em' }}>Toon afgehandelde items</Col>
                        <Col>
                            <Switch checked={showArchived} onClick={() => setShowArchived(!showArchived)} />
                        </Col>
                    </Row>
                </Space>
                <CollectionTable values={items} columns={columns} />
            </Space>
        </>
    );
};

export default SalesInvoiceDeviations;
