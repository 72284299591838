import '@src/models/types';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { App } from '@src/components';
import { when } from 'mobx';
import userStore from '@src/stores/User';
import screenStore from '@src/stores/Screen';
import 'mobx-react-lite/batchingForReactDom';
import './style.less';
import { setAutoFreeze } from 'immer';
import { firebaseApp } from '@src/services/firebaseService';
import { getFirestore } from 'firebase/firestore';

setAutoFreeze(false);

// TODO: Providers in here
const Root: React.FunctionComponent = () => <App />;

const rootElement = document.getElementById('root');
if (rootElement) {
    const root = createRoot(rootElement);
    root.render(<Root />);
}
when(
    () => userStore.firestoreUser?.admin,
    () => {
        console.log('Welcome to the console, available objects: firestore, screen, user');
        console.log("Example: (await firestore.collection('invoices').get()).docs[0].ref.delete()");
        (window as any).firestore = getFirestore(firebaseApp);
        (window as any).screen = screenStore;
        (window as any).user = userStore;
    },
);
