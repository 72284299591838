import { InferType } from 'yup';
import * as yup from 'yup';
import { FormPropertiesType } from './types';
import { Model } from '.';
import { fileSchema } from './sub/file';

const schema = yup.object({
    id: yup.string().required().label('ID'),
    isBroker: yup.boolean().required().label('Is broker?'),
    name: yup.string().required().label('Naam'),
    attn: yup.string().required().label('t.a.v'),
    address: yup.string().required().label('Adres'),
    city: yup.string().required().label('Stad'),
    postalCode: yup.string().required().label('Postcode'),
    country: yup.string().required().label('Land'),
    contactPerson: yup.string().label('Contactpersoon'),
    email: yup.string().email().label('E-mail adres'),
    customerNumber: yup.number().positive().integer().required().label('Klantnummer'),
    gAccount: yup.boolean().required().label('G-rekening?'),
    gAccountPercentage: yup
        .mixed()
        .label('G-rek. percentage')
        .when('gAccount', {
            is: true,
            then: yup.number().required().max(100).min(0),
            otherwise: yup.mixed().notRequired(),
        }),
    kvkNumber: yup.string().label('KvK nummer'),
    paymentTerm: yup.number().nullable().integer().min(1).label('Betaaltermijn (dagen)'),
    paymentTermWorkdays: yup.boolean().required().label('Betaaltermijn in werkdagen?'),
    vatNumber: yup.string().label('BTW Nummer'),
    hasVat: yup.boolean().required().label('BTW toepassen'),
    driveFolderID: yup.string(),
    files: yup.array(fileSchema),
});

type RecordType = InferType<typeof schema>;

const formProperties: FormPropertiesType<RecordType> = {
    id: {
        readonly: true,
    },
    isBroker: {
        default: false,
    },
    gAccount: {
        default: false,
    },
    gAccountPercentage: {
        readonly: false,
    },
    country: {
        default: 'Nederland',
    },
    hasVat: {
        default: true,
    },
    paymentTerm: {
        default: 14,
    },
    paymentTermWorkdays: {
        default: false,
    },
    driveFolderID: {
        hidden: true,
    },
    files: {
        hidden: true,
    },
};
export const Customer: Model<RecordType> = {
    collection: 'customers',
    schema,
    schemaDescription: schema.describe().fields,
    formProperties,
    defaultDisplay: (data) => data.name,
};

export type CustomerType = RecordType;
