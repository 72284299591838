import * as yup from 'yup';
import { InferType } from 'yup';

import { FormPropertiesType } from './types';
import { Model } from './index';

// prettier-ignore
export const schema = yup.object({
    id: yup.string().label('ID'),
    threadId: yup.string().label('Thread ID'),
    messageId: yup.string().label('Message ID'),
    subject: yup.string().label('Onderwerp'),
    body: yup.string().label('Inhoud'),
    from: yup.string().label('Van'),
    date: yup.mixed().label('Datum'),
    attachments: yup.array().of(yup.object({
        attachmentId: yup.string().label('Attachment ID'),
        path: yup.string().label('Pad'),
        status: yup.string().oneOf(['NEW', 'ACCEPTED', 'DECLINED'])
    })).label('Attachments'),
    status: yup.string().oneOf(['NEW', 'ACCEPTED', 'DECLINED', 'IGNORED']),
    type: yup.string().oneOf(['UNKNOWN', 'SUPPLIER_INVOICE', 'REGULAR_INVOICE', 'FEE_INVOICE', 'SALES_INVOICE']),
});

type RecordType = InferType<typeof schema>;

const formProperties: FormPropertiesType<RecordType> = {
    id: {
        readonly: true,
    },
    status: {
        hidden: true,
        default: 'NEW',
    },
    type: {
        default: 'UNKNOWN',
    },
    attachments: {
        default: [],
    },
};

export const IncomingEmail: Model<RecordType> = {
    collection: 'incoming_email',
    schema,
    schemaDescription: schema.describe().fields,
    formProperties,
    defaultDisplay: (data) => data.messageId,
};

export type IncomingEmailType = RecordType;
