import useDocument from '@src/hooks/useDocument';
import { Divider, Space } from 'antd';
import DeleteButton from '@src/components/DeleteButton';
import React from 'react';
import GoToButton from '@src/components/GoToButton';
import { userHasCapability } from '@src/utils/capabilities';
import { SalesInvoiceDeviation } from '@src/models/salesInvoiceDeviation';

export type InvoiceDeviationActionsProps = {
    id: string;
};

const InvoiceDeviationActions: React.FC<InvoiceDeviationActionsProps> = ({ id }) => {
    const [document] = useDocument(SalesInvoiceDeviation, id);

    if (!document) {
        return null;
    }

    return (
        <Space>
            <GoToButton path={`/${SalesInvoiceDeviation.collection}/${id}`} />

            <Divider />
            {userHasCapability('writeInvoices') && (
                <DeleteButton id={id} collection={SalesInvoiceDeviation.collection} />
            )}
        </Space>
    );
};

export default InvoiceDeviationActions;
