import React, { useState } from 'react';
import { Card } from 'antd';
import { useParams } from 'react-router-dom';
import useDocument from '@src/hooks/useDocument';
import { DataDisplay, ValueRenderers } from '@src/components/DataDisplay/DataDisplay';
import AddEditRecordModal from '@src/components/AddEditRecordModal';
import DetailLayout, { DetailLayoutContent, DetailLayoutMenu } from '@src/components/DetailLayout';
import { SideMenuProps } from '@src/components/SideMenu';
import { userHasCapability } from '@src/utils/capabilities';
import { SalesInvoice, SalesInvoiceType } from '@src/models/salesInvoice';
import { Project } from '@src/models/project';
import RecordLink from '@src/components/RecordLink';
import { Customer } from '@src/models/customer';
import { dateRenderer } from '@src/components/DataDisplay/renderers/valueRenderers';

const SalesInvoiceDetail: React.FC = () => {
    const [editModalOpen, setEditModalOpen] = useState(false);
    const { id } = useParams<{ id: string }>();
    const [invoiceDoc, { doc: docRef }] = useDocument<SalesInvoiceType>(SalesInvoice, id);
    const invoice = invoiceDoc?.data();
    const valueRenderers: ValueRenderers = {
        date: dateRenderer,
        customer: (record) => <RecordLink id={record.id} model={Customer} />,
        project: (record) => <RecordLink id={record.id} model={Project} />,
    };

    const openEditModal = () => setEditModalOpen(true);
    const closeEditModal = () => {
        setEditModalOpen(false);
    };

    const hasFolder = typeof invoice?.driveFolderID !== 'undefined';

    const actions: SideMenuProps['items'] = [
        { label: 'Bewerken', type: 'edit', onClick: openEditModal, disabled: !userHasCapability('writeRelations') },
        {
            label: 'Drive map openen',
            type: 'folder',
            disabled: !hasFolder || !userHasCapability('writeRelations'),
            onClick: () => {
                window.open(`https://drive.google.com/drive/u/0/folders/${invoice?.driveFolderID}`, '_blank');
            },
        },
    ];

    return invoice ? (
        <>
            {editModalOpen && (
                <AddEditRecordModal
                    isOpen={editModalOpen}
                    model={SalesInvoice}
                    type="Bewerken"
                    value={invoice}
                    getFormProperties={() => SalesInvoice.formProperties}
                    onCancel={closeEditModal}
                    onComplete={closeEditModal}
                />
            )}
            <DetailLayout title={`Factuur: ${invoice?.invoiceNumber || ''}`}>
                <DetailLayoutContent>
                    <Card title={'Details'} style={{ marginBottom: 16 }}>
                        <DataDisplay
                            displayFields={['date', 'invoiceNumber', 'project', 'customer']}
                            schemaDescription={SalesInvoice.schemaDescription}
                            value={invoice as any}
                            display="list"
                            valueRenderer={valueRenderers}
                        />
                    </Card>
                    <Card title={'Regels'} style={{ marginBottom: 16 }}>
                        {invoice.invoiceLines ? (
                            <DataDisplay
                                displayFields={['description', 'amount', 'rate']}
                                schemaDescription={SalesInvoice.schemaDescription.invoiceLines}
                                value={invoice.invoiceLines as any}
                                display="table"
                                valueRenderer={valueRenderers}
                            />
                        ) : (
                            <>Geen regels</>
                        )}
                    </Card>
                </DetailLayoutContent>
                <DetailLayoutMenu items={actions} />
            </DetailLayout>
        </>
    ) : (
        <div />
    );
};

export default SalesInvoiceDetail;
