import { Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import firestore, { getDoc } from 'firebase/firestore';
import { isEmployee } from '@src/utils/employeeOrConsultant';
import RecordLink from '../RecordLink';
import { Employee, EmployeeType } from '@src/models/employee';
import { ExternalConsultant, ExternalConsultantType } from '@src/models/externalConsultant';

export type EmployeeOrExternalConsultantLinkProps = {
    record: firestore.DocumentReference;
};

const EmployeeOrExternalConsultantLink: React.FC<EmployeeOrExternalConsultantLinkProps> = ({ record }) => {
    const [data, setData] = useState<EmployeeType | ExternalConsultantType>();

    const resolveRecord = async () => {
        if (record) {
            const item = await getDoc(record);
            const data = item.data();
            if (data) {
                setData(data as any);
            }
        }
    };

    useEffect(() => {
        if (!data) {
            resolveRecord();
        }
    }, [data]);

    if (!data) {
        return <Spin />;
    }

    return isEmployee(data) ? (
        <RecordLink id={data.id} model={Employee} />
    ) : (
        <RecordLink id={data.id} model={ExternalConsultant} />
    );
};

export default EmployeeOrExternalConsultantLink;
