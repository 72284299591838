import { InboxOutlined } from '@ant-design/icons';
import { WizardStepProps } from '@src/components/Wizard/Wizard';
import { DocumentTypeType } from '@src/models/documentType';
import { getFunctionUrl, getToken } from '@src/services/functions';
import { UploadedDocument } from '@src/utils/document';
import { Upload } from 'antd';
import { UploadChangeParam } from 'antd/lib/upload';
import React, { useEffect, useState } from 'react';

export type DocumentUploadProps = {
    folderId: string;
    onChange: (files: UploadedDocument[]) => void;
    documentType: DocumentTypeType;
} & WizardStepProps;

const DocumentUpload: React.FC<DocumentUploadProps> = ({ onChange, folderId, onStepComplete, documentType }) => {
    const [token, setToken] = useState('');

    useEffect(() => {
        (async () => {
            if (!token) {
                const newToken = await getToken();
                setToken(newToken || '');
            }
        })();
    }, [token, setToken]);

    const handleChange = (info: UploadChangeParam) => {
        if (info.file.status === 'done') {
            onChange(info.file.response);
            onStepComplete(true, true);
        }
    };

    return (
        <Upload.Dragger
            name="file"
            multiple={true}
            headers={{
                Authorization: `Bearer ${token}`,
            }}
            onChange={handleChange}
            data={{ folderId, subFolderName: documentType.folderName }}
            action={getFunctionUrl('uploadFile')}
        >
            <p className="ant-upload-drag-icon">
                <InboxOutlined />
            </p>
            <p className="ant-upload-text">Klik of sleep hier</p>
            <p className="ant-upload-hint">Meerdere bestanden zijn toegestaan</p>
        </Upload.Dragger>
    );
};

export default DocumentUpload;
