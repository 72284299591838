import React from 'react';
import { Tag } from 'antd';
import { DocumentTypeType } from '@src/models/documentType';

const UNKNOWN_COLOR = '#869DDB';
const UNKNOWN_DOCUMENT_TYPE = 'Onbekend';

export type DocumentTagProps = {
    documentType?: DocumentTypeType;
};

const DocumentTag: React.FC<DocumentTagProps> = ({ documentType }) => {
    return (
        <Tag style={{ color: 'black' }} color={documentType?.color || UNKNOWN_COLOR}>
            {documentType?.name || UNKNOWN_DOCUMENT_TYPE}
        </Tag>
    );
};

export default DocumentTag;
