import { Button, Popconfirm, Spin, Tooltip } from 'antd';
import React from 'react';
import HistoryOutlined from '@ant-design/icons/HistoryOutlined';
import { db } from '@src/services/firebaseService';
import { doc, updateDoc } from 'firebase/firestore';

type ArchiveButtonProps = {
    id?: string;
    collection?: string;
    onConfirm?: () => void;
    busy?: boolean;
    field?: string;
};

const ArchiveButton: React.FC<ArchiveButtonProps> = ({ id, collection, onConfirm, busy = false, field }) => (
    <Popconfirm
        placement="left"
        cancelText="Annuleren"
        title={'Weet je het zeker?'}
        onConfirm={() => {
            if (collection && id && field) {
                const docRef = doc(db, collection, id);
                updateDoc(docRef, { [field]: true });
            } else if (onConfirm) {
                onConfirm();
            }
        }}
    >
        <Tooltip title="Archiveren">
            {busy ? (
                <Spin />
            ) : (
                <Button shape="circle">
                    <HistoryOutlined />
                </Button>
            )}
        </Tooltip>
    </Popconfirm>
);

export default ArchiveButton;
