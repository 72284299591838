import * as yup from 'yup';
import { InferType } from 'yup';

import { FormPropertiesType } from './types';
import { Model } from './index';

// prettier-ignore
export const schema = yup.object({
    id: yup.string().label('ID'),
    customer: yup.mixed().reference('customer').required().label("Klant"),
    project: yup.mixed().reference('project').label("Project"),
    date: yup.date().required().label('Datum'),
    invoicePeriod: yup.object({
        from: yup.mixed().label("Van"),
        to:  yup.mixed().label("Tot"),
    }).label('Factuurperiode'),
    invoiceSequenceNumber: yup.number().label('Volgnummer'),
    invoiceNumber: yup.string().label('Factuurnummer'),
    invoiceLines: yup.array(yup.object({
        description: yup.string().required().label("Beschrijving"),
        amount: yup.number().min(0).required().label("Hoeveelheid"),
        rate: yup.number().min(1).required().label("Tarief"),
        euroRate: yup.number().nullable(true),
        dollarRate: yup.number().nullable(true),
    })).min(1).required().label('Regels'),
    status: yup.string().oneOf(['IDLE', 'GENERATING_PREVIEW', 'FINALIZING', 'FINALIZED']),
    mailStatus: yup.string().oneOf(['IDLE', 'EMAILING', 'EMAILED']),
    invoiceType: yup.string().oneOf(['PROJECT', 'PLACEHOLDER', 'NORMAL', 'EXTERNAL']),
    draftID: yup.string().nullable(true),
    driveFolderID: yup.string(),
    attachmentsFolderID: yup.string(),
    pdfID: yup.string().nullable(true),
    reminderSent: yup.array(yup.string()),
});

type RecordType = InferType<typeof schema>;

const formProperties: FormPropertiesType<RecordType> = {
    id: {
        readonly: true,
    },
    invoiceNumber: {
        readonly: true,
    },
    invoiceSequenceNumber: {
        readonly: true,
        default: 0,
    },
    invoiceType: {
        readonly: true,
        hidden: true,
    },
    status: {
        hidden: true,
        default: 'IDLE',
    },
    mailStatus: {
        hidden: true,
        default: 'IDLE',
    },
    date: {
        default: new Date(),
    },
    draftID: {
        hidden: true,
    },
    driveFolderID: {
        hidden: true,
    },
    attachmentsFolderID: {
        hidden: true,
    },
    pdfID: {
        hidden: true,
    },
    invoicePeriod: {
        hidden: true,
    },
    reminderSent: {
        hidden: true,
        default: [],
    },
    customer: {
        hidden: false,
    },
    project: {
        hidden: false,
    },
};

export const SalesInvoice: Model<RecordType> = {
    collection: 'invoices',
    schema,
    schemaDescription: schema.describe().fields,
    formProperties,
    defaultDisplay: (data) => data.invoiceNumber,
};

export type SalesInvoiceType = RecordType;
