import React from 'react';

import StringField from './StringField';
import NumberField from './NumberField';
import ReferenceField from './ReferenceField';
import BooleanField from '@src/components/Form/fields/BooleanField';
import { SchemaFieldDescription, ValidationError } from 'yup';
import TableField from '@src/components/Form/fields/TableField';
import StringArrayField from '@src/components/Form/fields/StringArrayField';
import DateField from '@src/components/Form/fields/DateField';
import { FormPropertiesType, FormProperty } from '@src/models/types';
import TextAreaField from '@src/components/Form/fields/TextAreaField';
import RadioButtonFieldEmployeeType from './RadioButtons/RadioButtonFieldEmployeeType';
import RadioButtonFieldLegalForm from './RadioButtons/RadioButtonFieldLegalForm';
import RadioButtonFieldCurrency from './RadioButtons/RadioButtonFieldCurrency';
import RadioButtonFieldLanguage from './RadioButtons/RadioButtonFieldLanguage';
import ObjectField from './ObjectField';
import RadioButtonFieldSupplierType from './RadioButtons/RadioButtonFieldSupplierType';
import TimestampField from './TimestampField';
import TextSelectField from './TextSelectField';

export interface FieldProps<T> {
    value: T;
    originalValue: T;
    schemaDescription: SchemaFieldDescription;
    readonly?: boolean;
    errors: ValidationError[];
    onChange: (value: T) => void;
    formProperty?: FormProperty<T>;
    formProperties: FormPropertiesType<T>;
    rootValue?: any;
    onBlur: () => void;
    customFormFields: { [key in keyof Partial<T>]: React.ComponentType<FieldProps<T>> };
}
export const FormField = React.memo((props: FieldProps<any>) => {
    const { schemaDescription } = props;
    const type = schemaDescription.type;
    const fieldErrors = props.errors;

    const FieldComponent: any =
        type === 'string' && props.formProperty?.options
            ? TextSelectField
            : type === 'string' && props.formProperty?.textarea
            ? TextAreaField
            : type === 'string' && props.formProperty?.radioButtonFieldEmployee
            ? RadioButtonFieldEmployeeType
            : type === 'string' && props.formProperty?.radioButtonFieldLegal
            ? RadioButtonFieldLegalForm
            : type === 'string' && props.formProperty?.radioButtonFieldCurrency
            ? RadioButtonFieldCurrency
            : type === 'string' && props.formProperty?.radioButtonFieldLanguage
            ? RadioButtonFieldLanguage
            : type === 'string' && props.formProperty?.radioButtonFieldSupplierType
            ? RadioButtonFieldSupplierType
            : type === 'string'
            ? StringField
            : type === 'number'
            ? NumberField
            : type === 'boolean'
            ? BooleanField
            : type === 'date'
            ? DateField
            : type === 'mixed' &&
              'meta' in schemaDescription &&
              schemaDescription['meta'] &&
              'model' in schemaDescription.meta
            ? ReferenceField
            : type === 'mixed' &&
              'meta' in schemaDescription &&
              schemaDescription['meta'] &&
              'timestamp' in schemaDescription.meta
            ? TimestampField
            : type === 'array' && 'innerType' in schemaDescription && schemaDescription.innerType?.type === 'object'
            ? TableField
            : type === 'array' && 'innerType' in schemaDescription && schemaDescription.innerType?.type === 'string'
            ? StringArrayField
            : type === 'object'
            ? ObjectField
            : StringField;
    return (
        <>
            <FieldComponent {...props} errors={props.errors} />
            {!props.readonly && fieldErrors.length > 0 && type !== 'array' && (
                <div className="form--error">{fieldErrors.map((err) => err.message).join('. ')}</div>
            )}
        </>
    );
});

export default FormField;
