import React, { useEffect } from 'react';
import { Modal } from 'antd';
import { Model } from '@src/models';
import { createNew, updateOrCreate } from '@src/misc';
import { Form, FormProps } from '../Form';

type AddEditRecordModalProps<T extends { id: string }> = Omit<FormProps<T>, 'schemaDescription' | 'schema' | 'value'> &
    Partial<Pick<FormProps<T>, 'value'>> & {
        model: Model<T>;
        newRecord?: () => T | Promise<T>;
        type?: string;
        isOpen: boolean;
        onCancel: () => void;
        onComplete: (value: T) => void;
    };

const AddEditRecordModal = <T extends { id: string }>({
    model,
    newRecord,
    changeHook,
    getFormProperties,
    customFormFields,
    type,
    isOpen,
    onCancel,
    onComplete,
    value,
}: AddEditRecordModalProps<T>): JSX.Element => {
    const [record, setRecord] = React.useState<T | null>(value || null);

    const onSubmit = async (value: T) => {
        await updateOrCreate(model, value);
        onComplete(value);
    };

    const handleNewRecord = async () => {
        if (newRecord) {
            setRecord(await newRecord());
        } else {
            setRecord(createNew(model));
        }
    };

    useEffect(() => {
        if (!record) {
            handleNewRecord();
        }
    }, [record, setRecord, newRecord]);

    return (
        <>
            {isOpen && record && (
                <Modal
                    title={type || 'Nieuw'}
                    width={850}
                    open={isOpen}
                    footer={null}
                    onCancel={onCancel}
                    style={type === 'Nieuw project' ? { maxWidth: '1300px' } : { maxWidth: 960 }}
                >
                    <div>
                        <Form
                            onCancel={onCancel}
                            onSubmit={onSubmit}
                            getFormProperties={getFormProperties || (() => model.formProperties)}
                            schemaDescription={model.schemaDescription}
                            schema={model.schema}
                            changeHook={changeHook}
                            value={record}
                            customFormFields={customFormFields}
                        />
                    </div>
                </Modal>
            )}
        </>
    );
};

export default AddEditRecordModal;
