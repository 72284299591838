import React from 'react';
import { InputNumber } from 'antd';
import { FieldProps } from '.';

const NumberField = ({ value, onChange, readonly, onBlur }: FieldProps<number>): JSX.Element => {
    return (
        <InputNumber disabled={readonly} value={value} onBlur={onBlur} onChange={(num) => onChange(num as number)} />
    );
};

export default NumberField;
