import { InferType } from 'yup';
import * as yup from 'yup';
import { FormPropertiesType } from './types';
import { Model } from '.';

const schema = yup.object({
    id: yup.string().required().label('ID'),
    admin: yup.boolean().notRequired().label('Admin?'),
    displayName: yup.string().required().label('Naam'),
    email: yup.string().email().required().label('E-mail'),
    photoURL: yup.string().url().required().label('Foto URL'),
    capabilities: yup
        .object({
            readInvoices: yup.boolean().required().label('Facturen inzien'),
            writeInvoices: yup.boolean().required().label('Facturen maken'),
            deleteInvoices: yup.boolean().required().label('Facturen verwijderen'),

            readRelations: yup.boolean().required().label('Relaties inzien'),
            writeRelations: yup.boolean().required().label('Relaties maken'),
            deleteRelations: yup.boolean().required().label('Relaties verwijderen'),

            readProjects: yup.boolean().required().label('Projecten inzien'),
            writeProjects: yup.boolean().required().label('Projecten maken'),
            deleteProjects: yup.boolean().required().label('Projecten verwijderen'),

            readEmployees: yup.boolean().required().label('Medewerkers inzien'),
            readSensitiveEmployees: yup.boolean().required().label('Medewerkers gevoelige informatie inzien'),
            writeEmployees: yup.boolean().required().label('Medewerkers maken'),
            deleteEmployees: yup.boolean().required().label('Medewerkers verwijderen'),

            readOffers: yup.boolean().required().label('Aanbiedingen inzien'),
            writeOffers: yup.boolean().required().label('Aanbiedingen maken'),
            deleteOffers: yup.boolean().required().label('Aanbiedingen verwijderen'),

            userManagement: yup.boolean().required().label('Gebruiker beheer'),
            settingsManagement: yup.boolean().required().label('Instellingen beheer'),
        })
        .label('Rechten'),
});

type RecordType = InferType<typeof schema>;

const formProperties: FormPropertiesType<RecordType> = {
    id: {
        readonly: true,
    },
    admin: {
        readonly: false,
    },
    displayName: {
        readonly: true,
    },
    email: {
        readonly: true,
    },
    photoURL: {
        readonly: true,
    },
};

export const User: Model<RecordType> = {
    collection: 'users',
    schema,
    schemaDescription: schema.describe().fields,
    formProperties,
    defaultDisplay: (data) => data.displayName,
};
export type UserType = RecordType;
