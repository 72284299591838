import { InferType } from 'yup';
import * as yup from 'yup';
import { FormPropertiesType } from './types';
import { Model } from '.';
import { fileSchema } from './sub/file';
import { notificationSchema } from './sub/notification';

const schema = yup.object({
    files: yup.array(fileSchema),
    driveFolderID: yup.string(),
    notifications: yup.array(notificationSchema),
});

type RecordType = InferType<typeof schema>;

const formProperties: FormPropertiesType<RecordType> = {
    files: {
        hidden: true,
    },
    driveFolderID: {
        hidden: true,
    },
    notifications: {
        hidden: true,
    },
};
export const Documents: Model<RecordType> = {
    collection: 'kv',
    schema,
    schemaDescription: schema.describe().fields,
    formProperties,
    defaultDisplay: () => 'documents',
};

export type DocumentsType = RecordType;
