import React from 'react';
import { Alert } from 'antd';
import { NotificationType } from '@src/models/sub/notification';

export type NotificationListProps = {
    notifications?: NotificationType[];
};

export const NotificationList: React.FC<NotificationListProps> = ({ notifications = [] }) => {
    return (
        <>
            {notifications.map((notification) => (
                <Alert
                    key={notification.message}
                    message={notification.message}
                    type={notification.severity === 'HIGH' ? 'error' : 'warning'}
                    closable
                    showIcon
                    style={{ marginBottom: 10 }}
                />
            ))}
        </>
    );
};
