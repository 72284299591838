import React from 'react';
import { ColumnsType } from 'antd/lib/table';
import AddRecordButton from '@src/components/AddRecordButton';
import DeleteButton from '@src/components/DeleteButton';
import CollectionTable from '@src/components/CollectionTable';
import useCollection from '@src/hooks/useCollection';
import { Form } from '@src/components/Form';
import { updateOrCreate } from '@src/misc';
import { PageHeader, Space } from 'antd';
import { userHasCapability } from '@src/utils/capabilities';
import { DocumentType, DocumentTypeType } from '@src/models/documentType';
import TagsField from '@src/components/Form/fields/TagsField';

const columns: ColumnsType<DocumentTypeType> = [
    {
        title: 'Naam',
        dataIndex: 'name',
        key: 'name',
        defaultSortOrder: 'ascend',
        sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
        title: 'Beschrijving',
        dataIndex: 'description',
        key: 'description',
        sorter: (a, b) => a.description.localeCompare(b.description),
    },
    {
        title: 'Actie',
        dataIndex: 'id',
        key: 'x',
        render: (_, record) => (
            <>
                {userHasCapability('settingsManagement') && (
                    <DeleteButton id={record.id} collection={DocumentType.collection} />
                )}
            </>
        ),
    },
];

const DocumentTypes = (): JSX.Element => {
    const items = useCollection(DocumentType);

    const getFormProperties = () => DocumentType.formProperties;
    const customFormFields = {
        requiredFor: TagsField as any,
        allowedFor: TagsField as any,
    };

    return (
        <>
            <Space style={{ width: '100%' }} direction="vertical" size="large">
                <PageHeader title="Document typen" />
                {userHasCapability('writeRelations') && (
                    <AddRecordButton
                        value="Nieuw document type"
                        model={DocumentType}
                        customFormFields={customFormFields}
                        getFormProperties={getFormProperties}
                    />
                )}
                <CollectionTable
                    values={items}
                    columns={columns}
                    Expander={
                        userHasCapability('settingsManagement')
                            ? ({ value, onClose }) => (
                                  <Form
                                      onSubmit={(updatedValue) => {
                                          updateOrCreate(DocumentType, updatedValue);
                                          onClose();
                                      }}
                                      onCancel={onClose}
                                      value={value}
                                      customFormFields={customFormFields}
                                      schema={DocumentType.schema}
                                      schemaDescription={DocumentType.schemaDescription}
                                      getFormProperties={getFormProperties}
                                  />
                              )
                            : undefined
                    }
                />
            </Space>
        </>
    );
};

export default DocumentTypes;
