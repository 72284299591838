import { Card, Switch } from 'antd';
import React from 'react';
import DocumentList, { DocumentListProps } from '../DocumentList';

export type DocumentCardProps = Omit<DocumentListProps, 'archived'>;

export const DocumentCard: React.FC<DocumentCardProps> = ({
    documents,
    onDocumentDelete,
    onDocumentArchive,
    filterTypes,
}) => {
    const [showArchived, setShowArchived] = React.useState<boolean>(false);

    return (
        <Card
            title={
                <div style={{ display: 'flex', width: '100%' }}>
                    <span style={{ flex: 1 }}>Documenten</span>
                    <span style={{ fontWeight: 300 }}>
                        Toon gearchiveerd:{' '}
                        <Switch checked={showArchived} onChange={(checked) => setShowArchived(checked)} />
                    </span>
                </div>
            }
            style={{ marginBottom: 16 }}
        >
            <DocumentList
                documents={documents}
                onDocumentDelete={onDocumentDelete}
                onDocumentArchive={onDocumentArchive}
                showArchived={showArchived}
                filterTypes={filterTypes}
            />
        </Card>
    );
};
