import firestore, { collection, doc, setDoc } from 'firebase/firestore';
import { SchemaDescription } from 'yup';
import { Model } from '@src/models';
import moment from 'moment';
import { db } from '@src/services/firebaseService';

export const updateSnapshot = async <T>(snapshot: firestore.DocumentSnapshot<T>, data: Partial<T>): Promise<void> => {
    setDoc(snapshot.ref, data as T, { merge: true });
};

export const updateOrCreate = async <T extends { id: string }>(
    model: Model<T>,
    data: Partial<T>,
): Promise<Partial<T>> => {
    if (!data.id) {
        const docRef = doc(collection(db, model.collection));
        data.id = docRef.id;

        await setDoc(docRef, data as T);
    } else {
        const docRef = doc(db, model.collection, data.id);
        await setDoc(docRef, data as T, { merge: true });
    }

    return data;
};

export const createNew = <T extends { id: string }>(model: Model<T>, initialValues?: Partial<T>): T => {
    const newRecord: Record<string, any> = {};
    const keys = Object.keys(model.schemaDescription) as Array<keyof T>;

    keys.forEach((key) => {
        const fieldDescription = model.schemaDescription[key];

        if (initialValues && key in initialValues) {
            newRecord[key as string] = initialValues[key];
            // @ts-expect-error "Object is possibly 'undefined'."
        } else if (key in model.formProperties && 'default' in model.formProperties[key]) {
            newRecord[key as string] =
                typeof model.formProperties[key]!.default === 'function'
                    ? //@ts-expect-error "Cannot invoke an object which is possibly 'undefined'"
                      model.formProperties[key]!.default()
                    : model.formProperties[key]!.default;
        } else if (fieldDescription.type === 'array') {
            newRecord[key as string] = [];
        } else if (fieldDescription.type === 'string') {
            newRecord[key as string] = '';
        } else if (fieldDescription.type === 'date') {
            newRecord[key as string] = moment().format('YYYY-MM-DD');
        } else {
            newRecord[key as string] = null;
        }
    });
    return newRecord as T;
};

export const fieldLabel = (schemaDescription: SchemaDescription['fields'], fieldName: string): string => {
    const field = schemaDescription[fieldName];
    return 'label' in field ? field.label : fieldName;
};
