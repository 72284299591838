import { useEffect } from 'react';

export function useEnterKey(onEnterPressed: () => void): void {
    useEffect(() => {
        function handleKeyPress(event: KeyboardEvent) {
            if (event.key === 'Enter') {
                onEnterPressed();
            }
        }

        window.addEventListener('keydown', handleKeyPress);
        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, [onEnterPressed]);
}
