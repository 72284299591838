import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Divider, Row, Progress } from 'antd';
import { useEnterKey } from '@src/hooks/useEnterKey';

export type WizardStepProps = {
    onStepComplete: (value: boolean, autoTransition?: boolean) => void;
};

export type WizardStep = {
    active: () => boolean;
    title: string;
    enterStep?: () => Promise<void>;
    func?: () => Promise<void> | Promise<void[]>;
    isFinal?: boolean;
    skipOnPrevious?: boolean;
    content?: (props: WizardStepProps) => React.ReactElement;
    nextButtonLabel?: string;
    previousButtonLabel?: string;
};

export type WizardProps = {
    steps: WizardStep[];
    contentRight?: React.ReactNode;
    contentRightWidth?: number;
    onStepChange?: (step: WizardStep) => void;
    onWizardComplete: () => void;
};

const Wizard: React.FC<WizardProps> = ({
    steps,
    onStepChange,
    onWizardComplete,
    contentRight,
    contentRightWidth = 12,
}) => {
    const [currentStepIndex, setCurrentStepIndex] = useState(0);
    const [currentStepComplete, setCurrentStepComplete] = useState(false);
    const stepRef = useRef<number>();
    const activeSteps = steps.filter((item) => item.active());
    const interactiveSteps = activeSteps.filter((item) => typeof item.func === 'undefined');
    const currentStep = activeSteps[currentStepIndex];
    const hasNext = activeSteps.length - 1 > currentStepIndex;
    const indexOfCurrentInteractiveStep = interactiveSteps.indexOf(currentStep);
    const progress = (indexOfCurrentInteractiveStep + 1) / interactiveSteps.length;

    const handleEnterPress = () => {
        if (hasNext && currentStepComplete && !currentStep.isFinal) {
            handleNext();
        } else if (currentStep.isFinal) {
            onWizardComplete();
        }
    };

    const handleNext = () => {
        if (hasNext) {
            setCurrentStepIndex((prev) => prev + 1);
            setCurrentStepComplete(false);
        }
    };

    const getPreviousStepIndex = (currentStepIndex: number): number => {
        let previousStepIndex = currentStepIndex - 1;
        while (
            previousStepIndex >= 0 &&
            (!activeSteps[previousStepIndex].active() || activeSteps[previousStepIndex].skipOnPrevious)
        ) {
            previousStepIndex--;
        }

        return previousStepIndex;
    };

    const handlePrevious = () => {
        if (currentStepIndex > 0) {
            const previousStepIndex = getPreviousStepIndex(currentStepIndex);
            setCurrentStepIndex(previousStepIndex);
            setCurrentStepComplete(false);
        }
    };

    const handleStepComplete = (value: boolean, autoTransition?: boolean) => {
        if (value && autoTransition) {
            handleNext();
        } else {
            setCurrentStepComplete(value);
        }
    };

    useEffect(() => {
        if (currentStep && onStepChange && stepRef.current !== currentStepIndex) {
            stepRef.current = currentStepIndex;

            onStepChange(currentStep);

            if (currentStep.func) {
                currentStep.func().then(handleNext);
            }
        }
    }, [currentStepIndex, currentStep, onStepChange, stepRef, setCurrentStepComplete, handleStepComplete]);

    function getNextButtonLabel() {
        if (currentStep.nextButtonLabel) return currentStep.nextButtonLabel;
        if (Boolean(currentStep.isFinal)) return 'Sluiten';

        return 'Volgende';
    }

    useEnterKey(handleEnterPress);

    return (
        <div>
            {contentRight ? (
                <Row>
                    <Col span={24 - contentRightWidth}>
                        {currentStep.content && currentStep.content({ onStepComplete: handleStepComplete })}
                    </Col>
                    <Col span={1} style={{ textAlign: 'center' }}>
                        <Divider type="vertical" style={{ height: '100%' }} />
                    </Col>
                    <Col span={contentRightWidth - 1}>{contentRight}</Col>
                </Row>
            ) : (
                currentStep.content && currentStep.content({ onStepComplete: handleStepComplete })
            )}
            <Progress showInfo={false} success={{ percent: progress * 100 }} style={{ marginBottom: 16 }} />

            {currentStep.func ? null : (
                <>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        {Boolean(currentStep.isFinal) === false ? (
                            <Button shape="round" key="back" onClick={handlePrevious}>
                                {currentStep.previousButtonLabel || 'Vorige'}
                            </Button>
                        ) : (
                            <div />
                        )}
                        <Button
                            shape="round"
                            key="next"
                            type="primary"
                            disabled={!((hasNext && currentStepComplete) || currentStep.isFinal)}
                            onClick={currentStep.isFinal ? onWizardComplete : handleNext}
                        >
                            {getNextButtonLabel()}
                        </Button>
                    </div>
                </>
            )}
        </div>
    );
};

export default Wizard;
