import { Button, Tooltip } from 'antd';
import React from 'react';
import { InfoCircleOutlined } from '@ant-design/icons/';
import { NavLink } from 'react-router-dom';

interface GoToButtonProps {
    path: string;
}

const GoToButton = ({ path }: GoToButtonProps): JSX.Element => (
    <Tooltip title="Naar details">
        <Button shape="circle">
            <NavLink to={`${path}`}>
                <InfoCircleOutlined />
            </NavLink>
        </Button>
    </Tooltip>
);

export default GoToButton;
