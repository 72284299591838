import React, { useCallback, useState } from 'react';
import AddDocumentWizard, { AddDocumentWizardResult } from '@src/components/AddDocumentWizard/AddDocumentWizard';
import { updateSnapshot } from '@src/misc';
import firestore from 'firebase/firestore';
import { Document } from '@src/utils/document';
import DetailLayout, { DetailLayoutContent, DetailLayoutMenu } from '@src/components/DetailLayout';
import { SideMenuProps } from '@src/components/SideMenu';

import { userHasCapability } from '@src/utils/capabilities';
import { NotificationList } from '@src/components/NotificationList';
import { DocumentCard } from '@src/components/DocumentCard';
import useDocument from '@src/hooks/useDocument';
import { Documents, DocumentsType } from '@src/models/documents';
import { DocumentScope } from '@src/models/documentType';
import { callFunctionWithBody } from '@src/services/functions';

const DocumentsOverview: React.FC = () => {
    const [addDocumentWizardOpen, setDocumentWizardOpen] = useState(false);
    const [documentsDoc] = useDocument<DocumentsType>(Documents, 'documents');
    const documentsData = documentsDoc?.data();
    const hasFolder = Boolean(documentsData?.driveFolderID);

    const handleDocumentUploaded = useCallback(
        async (documents: AddDocumentWizardResult) => {
            await updateSnapshot<DocumentsType>(documentsDoc as firestore.DocumentSnapshot<DocumentsType>, {
                files: [...(documentsData?.files || []), ...documents],
            });
        },
        [documentsData, documentsDoc],
    );

    const handleDocumentDelete = async (document: Document) => {
        if (documentsDoc) {
            await updateSnapshot<DocumentsType>(documentsDoc as firestore.DocumentSnapshot<DocumentsType>, {
                files: documentsData?.files.filter((file) => file.id !== document.id),
            });
        }
    };

    const handleDocumentArchive = async (document: Document) => {
        if (documentsDoc) {
            await callFunctionWithBody('moveFile', {
                fileId: document.id,
                folderId: documentsData?.driveFolderID,
                path: 'ARCHIVED',
            });

            await updateSnapshot<DocumentsType>(documentsDoc as firestore.DocumentSnapshot<DocumentsType>, {
                files: documentsData?.files.map((file) => {
                    if (file.id === document.id) {
                        return {
                            ...file,
                            archived: true,
                        };
                    }
                    return file;
                }),
            });
        }
    };

    const actions: SideMenuProps['items'] = [
        {
            label: 'Document toevoegen',
            type: 'upload',
            disabled: !hasFolder || !userHasCapability('writeProjects'),
            onClick: () => setDocumentWizardOpen(true),
        },
        {
            label: 'Drive map openen',
            type: 'folder',
            disabled: !hasFolder || !userHasCapability('writeProjects'),
            onClick: () => {
                window.open(`https://drive.google.com/drive/u/0/folders/${documentsData?.driveFolderID}`, '_blank');
            },
        },
    ];

    return documentsData ? (
        <>
            {addDocumentWizardOpen && (
                <AddDocumentWizard
                    isOpen={addDocumentWizardOpen}
                    folderId={documentsData.driveFolderID}
                    scope={DocumentScope.DOCUMENTS}
                    onComplete={() => setDocumentWizardOpen(false)}
                    storeDocumentRefs={handleDocumentUploaded}
                    onCancel={() => setDocumentWizardOpen(false)}
                />
            )}
            <NotificationList notifications={documentsData?.notifications} />
            <DetailLayout title="Algemene documenten">
                <DetailLayoutContent>
                    <p>
                        Op deze pagina vind je documenten die belangrijk zijn voor de bedrijfsvoering. Documenten die
                        regelmatig worden opgevraagd door klanten/brokers alsmede documenten die belangrijk zijn voor
                        NEN audits.
                    </p>
                    <DocumentCard
                        documents={(documentsData.files || []) as Document[]}
                        onDocumentDelete={handleDocumentDelete}
                        onDocumentArchive={handleDocumentArchive}
                    />
                </DetailLayoutContent>
                <DetailLayoutMenu items={actions} />
            </DetailLayout>
        </>
    ) : (
        <div />
    );
};

export default DocumentsOverview;
