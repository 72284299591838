import { DocumentType, DocumentTypeType } from '@src/models/documentType';
import { db } from '@src/services/firebaseService';
import { collection, getDocs, query, where } from 'firebase/firestore';

export type UploadedDocument = {
    id: string;
    fileName: string;
    mimeType: string;
};

export type Document = UploadedDocument & { documentType: any; meta: any; archived: boolean };

export const getDocumentTypeBySlug = async (slug: string): Promise<DocumentTypeType> => {
    const collectionRef = collection(db, DocumentType.collection);
    const querySnapshot = query(collectionRef, where('slug', '==', slug));
    const docs = await getDocs(querySnapshot);

    if (docs.empty) {
        throw new Error(`Document type with slug ${slug} not found`);
    }

    return docs.docs[0].data() as DocumentTypeType;
};

// export const documentSubFolder: { [key in DocumentType]: string } = {
//     'chamber-of-commerce': 'KvK uittreksel',
//     'framework-agreement': 'Raamovereenkomst',
//     'g-account': 'G-Rekening',
//     'identity-proof': 'Identiteitsvaststelling',
//     insurance: 'Verzekering',
//     'project-agreement': 'Deelovereenkomst',
//     'project-agreement-supplier': 'Deelovereenkomst',
//     'assignment-agreement': 'Overeenkomst tot opdracht',
//     invoice: 'Inkoopfacturen',
//     timesheet: 'Urenstaten',
//     'labor-contract': 'Arbeidsovereenkomst',
//     letter: 'Brieven',
//     'information-sheet': 'Identiteit en informatie',
//     identity: 'Identiteit en informatie',
//     'lease-contract': 'Leaseovereenkomsten',
//     'tax-form': 'Loonbelastingformulier',
//     'performance-review': 'Beoordelingsformulieren',
//     unknown: 'Overig',
// };

// export const documentColors: { [key in DocumentType]: string } = {
//     'chamber-of-commerce': '#CEC9DF',
//     'framework-agreement': '#FDF1CA',
//     'g-account': '#EDCCB6',
//     'identity-proof': '#B2C6DE',
//     insurance: '#E1E1E1',
//     'project-agreement': '#C8DCB8',
//     'project-agreement-supplier': '#C8DCB8',
//     'assignment-agreement': '#C8DCB8',
//     invoice: '#EBE399',
//     timesheet: '#B6DDAC',
//     'labor-contract': '#ad8ca9',
//     letter: '#b89295',
//     'information-sheet': '#80a387',
//     identity: '#cf8096',
//     'lease-contract': '#a083e9',
//     'tax-form': '#98c8db',
//     'performance-review': '#BC85A3',
//     unknown: '#869DDB',
// };
