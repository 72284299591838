import axios from 'axios';
import { getAuth } from 'firebase/auth';
import { firebaseApp, firebaseConfig } from '../firebaseService';

const asQueryString = (params: Record<string, any> = {}): string => {
    const searchParams = new URLSearchParams();
    Object.keys(params).forEach((paramKey) => {
        searchParams.append(paramKey, params[paramKey]);
    });
    return searchParams.toString();
};

const BASE_URL = `https://europe-west3-${firebaseConfig.projectId}.cloudfunctions.net/`;

export const getToken = async () => {
    const token = await getAuth(firebaseApp).currentUser?.getIdToken();
    return token;
};

export const getFunctionUrl = (functionName: string, params?: Record<string, any>): string => {
    const qs = asQueryString(params);
    const baseUrl = BASE_URL + functionName;
    return qs ? baseUrl + '?' + qs : baseUrl;
};

export const callFunction = async <T>(functionName: string, params?: Record<string, any>): Promise<T | void> => {
    const url = getFunctionUrl(functionName, params);

    const token = await getToken();
    if (!token) throw new Error("Couldn't get firebase token.");

    try {
        const result = await axios.get(url, {
            withCredentials: false,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        return result.data as T;
    } catch (e) {
        throw new Error('Request failed.');
    }
};

export const callFunctionWithBody = async <T>(
    functionName: string,
    body: Record<string, any>,
    params?: Record<string, any>,
): Promise<T | void> => {
    const url = getFunctionUrl(functionName, params);

    const token = await getToken();
    if (!token) throw new Error("Couldn't get firebase token.");

    try {
        const result = await axios.post(url, body, {
            withCredentials: false,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        return result.data as T;
    } catch (e) {
        throw new Error('Request failed.');
    }
};
