import { UserType } from '@src/models/user';
import userStore from '@src/stores/User';
import { toJS } from 'mobx';

export type Capabilitiy = keyof UserType['capabilities'];

export const filterOnCapability = (items: [Capabilitiy, any][]): any[] => {
    return items.filter(([capability]) => userHasCapability(capability)).map(([, item]) => item);
};

export const userHasCapability = (capability: Capabilitiy): boolean => {
    const user = toJS(userStore.firestoreUser);

    if (!user || !user.capabilities) {
        return false;
    }

    return !!user.capabilities[capability];
};
