import { Button, Popconfirm, Spin, Tooltip } from 'antd';
import React from 'react';
import DeleteOutlined from '@ant-design/icons/DeleteOutlined';
import { db } from '@src/services/firebaseService';
import { deleteDoc, doc } from 'firebase/firestore';

type DeleteButtonProps = {
    id?: string;
    collection?: string;
    onConfirm?: () => void;
    busy?: boolean;
};

const DeleteButton = ({ id, collection, onConfirm, busy = false }: DeleteButtonProps): JSX.Element => (
    <Popconfirm
        placement="left"
        cancelText="Annuleren"
        title={'Weet je het zeker?'}
        onConfirm={async () => {
            if (collection && id) {
                await deleteDoc(doc(db, collection, id));
            } else if (onConfirm) {
                onConfirm();
            }
        }}
    >
        <Tooltip title="Verwijderen">
            {busy ? (
                <Spin />
            ) : (
                <Button danger={!busy} shape="circle">
                    <DeleteOutlined />
                </Button>
            )}
        </Tooltip>
    </Popconfirm>
);

export default DeleteButton;
