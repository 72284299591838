import React, { useEffect, useMemo, useState } from 'react';
import { Result, Modal, Spin } from 'antd';
import Wizard, { WizardStep } from '../Wizard/Wizard';
import SelectDocumentType from './steps/SelectDocumentType';
import DocumentUpload from './steps/DocumentUpload';
import { Document, getDocumentTypeBySlug, UploadedDocument } from '@src/utils/document';
import DocumentTimedMeta from './steps/DocumentTimedMeta';
import DocumentYearBoundMeta from './steps/DocumentYearBoundMeta';
import { DocumentMetaType, DocumentScope, DocumentTypeType, DocumentType } from '@src/models/documentType';
import { doc } from 'firebase/firestore';
import { db } from '@src/services/firebaseService';

export type AddDocumentWizardResult = Document[];

export type AddFilesWizardProps = {
    folderId: string;
    isOpen: boolean;
    onCancel: () => void;
    storeDocumentRefs: (documents: AddDocumentWizardResult) => Promise<void>;
    onComplete: () => void;
    scope: DocumentScope;
    initialDocumentType?: string;
};

const AddDocumentWizard: React.FC<AddFilesWizardProps> = ({
    isOpen,
    onCancel,
    onComplete,
    folderId,
    storeDocumentRefs,
    scope,
    initialDocumentType,
}) => {
    const [currentStep, setCurrentStep] = useState<WizardStep>();
    const [documentType, setDocumentType] = useState<DocumentTypeType>({} as DocumentTypeType);
    const [files, setFiles] = useState<UploadedDocument[]>([]);
    const [meta, setMeta] = useState<any>();

    // const hasTimedMeta: DocumentType[] = [
    //     'chamber-of-commerce',
    //     'assignment-agreement',
    //     'project-agreement',
    //     'project-agreement-supplier',
    //     'letter',
    //     'identity',
    // ];
    // const hasYearBoundMeta: DocumentType[] = ['performance-review', 'insurance'];

    const steps: WizardStep[] = useMemo(() => {
        return [
            // Generic
            {
                active: () => !initialDocumentType,
                title: 'Wat voor document wil je uploaden?',
                previousButtonLabel: 'Annuleren',
                content: (props) => (
                    <SelectDocumentType
                        scope={scope}
                        onChange={setDocumentType}
                        value={documentType}
                        onStepComplete={props.onStepComplete}
                    />
                ),
            },
            {
                active: () => true,
                title: 'Selecteer document(en)',
                content: (props) => (
                    <DocumentUpload
                        onStepComplete={props.onStepComplete}
                        documentType={documentType}
                        folderId={folderId}
                        onChange={setFiles}
                    />
                ),
            },
            {
                active: () =>
                    documentType.metaType === DocumentMetaType.CUSTOM_EXPIRATION ||
                    documentType.metaType === DocumentMetaType.THREE_MONTHLY_EXPIRATION ||
                    documentType.metaType === DocumentMetaType.YEARLY_EXPIRATION ||
                    documentType.metaType === DocumentMetaType.DATED,
                title: 'Vul de extra gegevens in',
                content: (props) => (
                    <DocumentTimedMeta
                        documentType={documentType}
                        onStepComplete={props.onStepComplete}
                        value={meta}
                        onChange={setMeta}
                    />
                ),
            },
            {
                active: () => documentType.metaType === DocumentMetaType.YEAR_BOUND,
                title: 'Vul de jaargang van dit document in',
                content: (props) => (
                    <DocumentYearBoundMeta
                        documentType={documentType}
                        onStepComplete={props.onStepComplete}
                        value={meta}
                        onChange={setMeta}
                    />
                ),
            },
            {
                title: 'Bezig met verwerken',
                active: () => true,
                func: async () => {
                    await storeDocumentRefs(
                        files.map((file) => ({
                            ...file,
                            documentType: doc(db, DocumentType.collection, documentType.id),
                            meta: meta || null,
                            archived: false,
                        })),
                    );
                },
                content: () => <Spin />,
            },
            {
                active: () => true,
                title: 'Klaar',
                isFinal: true,
                content: () => (
                    <Result
                        status="success"
                        title="Documenten zijn toegevoegd"
                        subTitle="Je kunt dit venster nu sluiten."
                    />
                ),
            },
        ];
    }, [files, setFiles, documentType, setDocumentType, meta]);

    const handleStepChange = (step: WizardStep) => {
        setCurrentStep(step);
    };

    useEffect(() => {
        if (initialDocumentType) {
            getDocumentTypeBySlug(initialDocumentType).then(setDocumentType).catch(console.error);
        }
    }, [initialDocumentType]);

    return (
        <Modal title={currentStep?.title} width={960} open={isOpen} onCancel={onCancel} footer={null}>
            <Wizard steps={steps} onStepChange={handleStepChange} onWizardComplete={onComplete} />
        </Modal>
    );
};

export default AddDocumentWizard;
