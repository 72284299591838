import React from 'react';
import { Input } from 'antd';
import { FieldProps } from '.';

const TextAreaField = ({ value, onChange, readonly, onBlur }: FieldProps<string>): JSX.Element => {
    return (
        <Input.TextArea
            rows={10}
            disabled={readonly}
            defaultValue={value}
            onBlur={(e) => {
                onChange(e.target.value);
                onBlur();
            }}
        />
    );
};

export default TextAreaField;
