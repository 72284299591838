import React from 'react';
import { Select } from 'antd';
import { FieldProps } from '@src/components/Form/fields';

export default function TagsField({ formProperty, value, readonly, onBlur, onChange }: FieldProps<any>): JSX.Element {
    const options = formProperty?.options ?? [];

    return (
        <Select
            options={options.map((option) => (typeof option === 'string' ? { label: option, value: option } : option))}
            style={{ width: '100%' }}
            disabled={readonly}
            mode="tags"
            value={value}
            onBlur={onBlur}
            onChange={onChange}
        />
    );
}
