import React from 'react';
import firestore from 'firebase/firestore';
import { Select, Spin } from 'antd';
import { FieldProps } from '.';
import Models from '@src/models';
import { sortBy } from 'lodash';
import { firestoreDoc, firestoreDocs } from '@src/services/firebaseService';

const ReferenceField = ({
    value,
    schemaDescription,
    onChange,
    readonly,
    onBlur,
}: FieldProps<{ id: string }>): JSX.Element | null => {
    if (!('meta' in schemaDescription) || !('model' in schemaDescription.meta)) {
        return null;
    }
    const referedType = Models[(schemaDescription.meta as any).model as keyof typeof Models];

    if (!referedType) {
        return null;
    }
    const [options, setOptions] = React.useState<null | firestore.QueryDocumentSnapshot<any>[]>(null);
    React.useEffect(() => {
        (async () => {
            const result = await firestoreDocs(referedType.collection);
            setOptions(sortBy(result.docs, (v) => referedType.defaultDisplay(v.data())));
        })();
    }, []);
    if (!options) {
        return <Spin />;
    }

    const change = async (selectedValue: string | null) => {
        if (selectedValue) {
            onChange((await firestoreDoc(referedType.collection, selectedValue)).ref);
        } else {
            onChange({ id: null as any });
        }
    };

    return (
        <Select
            style={{ width: '100%' }}
            disabled={readonly}
            value={value?.id || (null as any)}
            onChange={change as any}
            onBlur={onBlur}
        >
            <Select.Option value={null as any} key={null as any}>
                <span>Kies...</span>
            </Select.Option>
            {options.map((option) => (
                <Select.Option value={option.ref.id} key={option.ref.id}>
                    <span>{referedType.defaultDisplay(option.data())}</span>
                </Select.Option>
            ))}
        </Select>
    );
};

export default ReferenceField;
