import moment from 'moment';
import React from 'react';
import { Typography } from 'antd';
import ExclamationCircleOutlined from '@ant-design/icons/ExclamationCircleOutlined';
import { Capabilitiy, userHasCapability } from '@src/utils/capabilities';
import RecordLink from '@src/components/RecordLink';
import EmployeeOrExternalConsultantLink from '@src/components/EmployeeOrExternalConsultantLink';
import { Timestamp } from 'firebase/firestore';
import { convertDateToFormattedDutchDate } from '@src/utils/timestamp';

export type ValueRenderer = (value: any, item?: any) => JSX.Element | string | any;

export const boldRenderer: ValueRenderer = (value: string) => <Typography.Text strong>{value}</Typography.Text>;

export const booleanRenderer: ValueRenderer = (value: boolean) => (value ? 'Ja' : 'Nee');

export const dateRenderer: ValueRenderer = (value: string) => moment(value).format('DD-MM-YYYY');

export const formattedDutchDateRenderer: ValueRenderer = (value: Timestamp) => convertDateToFormattedDutchDate(value.toDate());

export const endDateRenderer: ValueRenderer = (value, item) => {
    const date = moment(value);
    const display = dateRenderer(value, item);

    if (date.isBefore(Date.now())) {
        return (
            <>
                {display}
                <ExclamationCircleOutlined style={{ color: 'red', marginLeft: 10 }} />
            </>
        );
    }

    return display;
};

export const euroRenderer: ValueRenderer = (value: string) =>
    `€ ${parseFloat(value).toLocaleString('nl-NL', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) || 0}`;

export const emailRenderer: ValueRenderer = (email: string) => {
    return <a href={`mailto:${email}`}>{email}</a>;
};

export const postfixRenderer =
    (postfix: string): ValueRenderer =>
    (value: string) =>
        `${value} ${postfix}`;

export const prefixRenderer =
    (prefix: string): ValueRenderer =>
    (value: string) =>
        `${prefix} ${value}`;

export const sensitiveValueRenderer =
    (capability: Capabilitiy, renderer: ValueRenderer = (value) => value): ValueRenderer =>
    (value: any, item) => {
        if (userHasCapability(capability)) {
            return renderer(value, item);
        } else {
            return '*************';
        }
    };

export const recordLinkRenderer =
    (model: any): ValueRenderer =>
    (value: any) =>
        <RecordLink model={model} id={value.id} />;

export const recordDisplayRenderer =
    (model: any): ValueRenderer =>
    (value: any) =>
        <RecordLink model={model} id={value.id} clickable={false} />;

export const employeeOrConsultantRenderer = (value: any) => <EmployeeOrExternalConsultantLink record={value} />;

export const copyableValueRenderer = (value: any) => <Typography.Text copyable>{value}</Typography.Text>;
