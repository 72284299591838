import React from 'react';
import { get } from 'lodash';
import { fieldLabel } from '@src/misc';
import { FormPropertiesType } from '@src/models/types';
import { Col, Row } from 'antd';
import { SchemaFieldDescription, ValidationError } from 'yup';
import FormField, { FieldProps } from '../fields';

export type CustomFormFields<T> = { [key in keyof Partial<T>]: React.ComponentType<FieldProps<T>> };

export type RenderPropsParams<T> = {
    schemaDescription: Record<string, SchemaFieldDescription>;
    formProperties: FormPropertiesType<T>;
    customFormFields?: CustomFormFields<T>;
    currentData: T;
    originalData: Partial<T>;
    handleChange: (fieldName: string, value: any) => void;
    isPristine: (fieldName: string) => boolean;
    errors: ValidationError[];
    handleTouchedField: (fieldName: string) => void;
};

export function renderFields<T>({
    schemaDescription,
    handleChange,
    isPristine,
    handleTouchedField,
    errors,
    currentData,
    customFormFields,
    formProperties,
    originalData,
}: RenderPropsParams<T>) {
    return Object.keys(schemaDescription)
        .filter((fieldName) => !formProperties[fieldName]?.hidden)
        .map((fieldName) => {
            const [flatFieldName] = fieldName.split('.').reverse();
            const Field =
                customFormFields && flatFieldName in customFormFields ? customFormFields[flatFieldName] : FormField;
            const fieldErrors = errors.filter((err) => err.path.match(`^${fieldName}($|\\[\\d+\\]\\..*)`));

            return (
                <Row key={fieldName} style={{ marginBottom: '1em', flexFlow: 'nowrap' }}>
                    <Col flex="200px">
                        <label htmlFor={fieldName}>{fieldLabel(schemaDescription, fieldName)}</label>
                    </Col>
                    <Col flex="auto">
                        <Field
                            schemaDescription={schemaDescription[fieldName]}
                            value={get(currentData, fieldName)}
                            formProperty={formProperties[fieldName]}
                            originalValue={originalData[fieldName]}
                            onChange={(value: T) => handleChange(fieldName, value)}
                            readonly={formProperties[fieldName]?.readonly}
                            errors={isPristine(fieldName) ? [] : fieldErrors}
                            rootValue={currentData}
                            onBlur={() => handleTouchedField(fieldName)}
                            customFormFields={customFormFields as any}
                            formProperties={formProperties}
                        />
                    </Col>
                </Row>
            );
        });
}
