import React from 'react';
import { DatePicker } from 'antd';
import { FieldProps } from '.';
import moment from 'moment';

const DateField = ({ value, onChange, readonly, onBlur }: FieldProps<string>): JSX.Element => {
    return (
        <div>
            <DatePicker
                format="DD-MM-YYYY"
                disabled={readonly}
                defaultValue={value ? (moment(value) as moment.Moment) : undefined}
                onChange={(e) => {
                    if (!e) {
                        return onChange(null);
                    }
                    onChange(e.format('YYYY-MM-DD'));
                }}
                onBlur={onBlur}
            />
        </div>
    );
};

export default DateField;
