import React, { useState } from 'react';
import List from 'antd/lib/list';
import ExclamationCircleOutlined from '@ant-design/icons/ExclamationCircleOutlined';
import { FileIcon, defaultStyles } from 'react-file-icon';
import { Document } from '@src/utils/document';
import { Tag } from 'antd';
import DeleteButton from '../DeleteButton';
import { callFunction } from '@src/services/functions';
import moment from 'moment';
import { TimedFileMetaType } from '@src/models/sub/timedFileMeta';
import DocumentTag from '../DocumentTag';
import { ProjectRelatedMetaType } from '@src/models/sub/projectRelatedFileMeta';
import RecordLink from '../RecordLink';
import { Project } from '@src/models/project';
import { YearBoundFileMetaType } from '@src/models/sub/yearBoundFileMeta';
import ArchiveButton from '../ArchiveButton';
import { Timestamp } from 'firebase/firestore';
import { DocumentTypeType } from '@src/models/documentType';

export type DocumentItemProps = {
    document: Document;
    documentTypes: DocumentTypeType[];
    onDocumentDelete: (document: Document) => void;
    onDocumentArchive?: (document: Document) => void;
};

const getMetaTag = (document: Document): React.ReactElement[] | null => {
    const tags = [];
    const timedMeta = document.meta as TimedFileMetaType;
    const projectRelatedMeta = document.meta as ProjectRelatedMetaType;
    const yearBoundMeta = document.meta as YearBoundFileMetaType;

    if (document.meta) {
        if (timedMeta.expire) {
            tags.push(
                <Tag key="expire-date">
                    Geldig van {moment(timedMeta.from).format('DD-MM-YYYY')} tot{' '}
                    {moment(timedMeta.expire).format('DD-MM-YYYY')}
                </Tag>,
            );
        }

        if (timedMeta.from) {
            tags.push(<Tag key="document-date">Documentdatum: {moment(timedMeta.from).format('DD-MM-YYYY')}</Tag>);
        }

        if (projectRelatedMeta.date) {
            tags.push(
                <Tag key="document-date-related">
                    Documentdatum:{' '}
                    {moment(
                        (projectRelatedMeta.date as unknown as Timestamp)?.toDate() || projectRelatedMeta.date,
                    ).format('DD-MM-YYYY')}
                </Tag>,
            );
        }

        if (projectRelatedMeta.project) {
            tags.push(
                <Tag key="record-link">
                    <RecordLink id={projectRelatedMeta.project.id} model={Project} />
                </Tag>,
            );
        }

        if (yearBoundMeta.year) {
            tags.push(<Tag key="year-bound">{yearBoundMeta.year}</Tag>);
        }

        return tags;
    }

    return null;
};

const DocumentItem: React.FC<DocumentItemProps> = ({
    document,
    onDocumentDelete,
    onDocumentArchive,
    documentTypes,
}) => {
    const [isDeleting, setIsDeleting] = useState(false);
    const [isArchiving, setIsArchiving] = useState(false);

    const handleDocumentDelete = async (document: Document) => {
        setIsDeleting(true);
        await callFunction('deleteFile', { fileId: document.id });
        setIsDeleting(false);
        onDocumentDelete(document);
    };

    const handleDocumentArchiving = async (document: Document) => {
        if (onDocumentArchive) {
            setIsArchiving(true);
            await onDocumentArchive(document);
            setIsArchiving(false);
        }
    };

    const isExpired =
        document.meta && (document.meta as TimedFileMetaType).expire
            ? moment((document.meta as TimedFileMetaType).expire).isBefore(moment.now())
            : false;
    const [extension] = document.fileName.split('.').reverse();
    const documentType = documentTypes?.find((item) => {
        if (typeof document.documentType === 'string') {
            return item.slug === document.documentType;
        }

        return item.id === document.documentType.id;
    });

    return (
        <List.Item
            key={document.id}
            actions={[
                ...(onDocumentArchive && document.archived !== true
                    ? [
                          <ArchiveButton
                              key="archive"
                              busy={isArchiving}
                              onConfirm={() => handleDocumentArchiving(document)}
                          />,
                      ]
                    : []),
                <DeleteButton key="delete" busy={isDeleting} onConfirm={() => handleDocumentDelete(document)} />,
            ]}
        >
            <List.Item.Meta
                avatar={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {isExpired && (
                            <ExclamationCircleOutlined style={{ color: 'red', fontSize: 24, marginRight: 16 }} />
                        )}
                        <div style={{ width: 48 }}>
                            <FileIcon extension={extension} {...defaultStyles[extension]} />
                        </div>
                    </div>
                }
                title={
                    <a target="_blank" href={`https://drive.google.com/file/d/${document.id}`} rel="noreferrer">
                        {document.fileName}
                    </a>
                }
                description={
                    <>
                        <DocumentTag documentType={documentType} />
                        {getMetaTag(document)}
                    </>
                }
            />
        </List.Item>
    );
};

export default DocumentItem;
