import React, { useEffect } from 'react';
import { WizardStepProps } from '@src/components/Wizard/Wizard';
import { Form, getDefaultValuesFromProperties } from '@src/components/Form';
import { YearBoundFileMeta, YearBoundFileMetaType } from '@src/models/sub/yearBoundFileMeta';
import { DocumentTypeType } from '@src/models/documentType';

export type DocumentMetaProps = {
    onChange: (meta: YearBoundFileMetaType) => void;
    documentType: DocumentTypeType;
    value?: YearBoundFileMetaType;
} & WizardStepProps;

const DocumentYearBoundMeta: React.FC<DocumentMetaProps> = ({ onChange, value, onStepComplete }) => {
    const getMetaFormProperties = () => YearBoundFileMeta.formProperties;

    const handleChange = (values: YearBoundFileMetaType) => {
        onChange(values);
        onStepComplete(true);
    };

    useEffect(() => {
        handleChange(
            value
                ? (value as any)
                : getDefaultValuesFromProperties<YearBoundFileMetaType>(YearBoundFileMeta.formProperties),
        );
    }, []);

    return (
        <Form
            value={
                value
                    ? (value as any)
                    : getDefaultValuesFromProperties<YearBoundFileMetaType>(YearBoundFileMeta.formProperties)
            }
            schema={YearBoundFileMeta.schema}
            onChange={handleChange as any}
            schemaDescription={YearBoundFileMeta.schemaDescription}
            getFormProperties={getMetaFormProperties}
        />
    );
};

export default DocumentYearBoundMeta;
