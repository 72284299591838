import React, { useEffect, useState } from 'react';
import { Button, Divider, Popover, Space } from 'antd';
import { FormPropertiesType } from '@src/models/types';
import { ValidationError, ObjectSchema, SchemaFieldDescription } from 'yup';
import { CustomFormFields, renderFields } from './utils/fieldRenderer';

export type FormProps<T extends { id: string }> = {
    readonly?: boolean;
    changeHook?: (oldValue: T, newValue: T) => T | Promise<T>;
    onSubmit?: (value: T) => void;
    onChange?: (value: T) => void;
    onCancel?: (value: T) => void;
    onValidation?: (valid: boolean) => void;
    value: T;
    schema: ObjectSchema<T>;
    schemaDescription: Record<string, SchemaFieldDescription>;
    getFormProperties: (record: T) => Promise<FormPropertiesType<T>> | FormPropertiesType<T>;
    customFormFields?: CustomFormFields<T>;
    disableSubmit?: boolean;
    showCancelButton?: boolean;
    showPopUp?: boolean;
};

export function getDefaultValuesFromProperties<T>(formProperties: FormPropertiesType<T>): T {
    return Object.keys(formProperties).reduce((acc, cur) => {
        return formProperties[cur] && formProperties[cur].default
            ? {
                  ...acc,
                  [cur]: formProperties[cur].default,
              }
            : acc;
    }, {}) as T;
}

export const Form = <T extends { id: string; [key: string]: any }>({
    value,
    schema,
    schemaDescription,
    getFormProperties,
    changeHook,
    onSubmit,
    onCancel,
    onChange,
    onValidation,
    customFormFields,
    disableSubmit = false,
    showCancelButton = true,
    showPopUp = false,
}: FormProps<T>): JSX.Element => {
    const [touchedFields, setTouchedFields] = useState<string[]>([]);
    const originalData = value;
    const [currentData, setCurrentData] = React.useState<T>(value);
    const [currentFormProperties, setCurrentFormProperties] = React.useState<FormPropertiesType<T>>();
    const handleChange = async (key: string, value: T) => {
        const newData = { ...currentData, [key]: value };
        if (onChange) {
            onChange(newData);
        }
        setCurrentData(changeHook ? await changeHook(currentData, newData) : newData);
    };

    const [open, setOpen] = useState(false);

    const handleOpenChange = (newOpen: boolean) => {
        setOpen(newOpen);
    };

    const [errors, setErrors] = React.useState<Array<ValidationError>>([]);

    const handleGetFormProperties = async () => {
        const formProperties = await getFormProperties(currentData);
        setCurrentFormProperties(formProperties);
    };

    const submit = () => onSubmit && onSubmit(currentData);
    const cancel = () => {
        onCancel && onCancel(currentData);
    };

    const isNew = !originalData.id;

    const blockingErrors = errors.filter((error) => error.path !== 'id');

    console.log(blockingErrors, currentData);

    const saveAllowed = blockingErrors.length === 0 && disableSubmit !== true;

    const handleTouchedField = (fieldName: string) => {
        const newTouchedFields = touchedFields.filter((field) => field !== fieldName);
        newTouchedFields.push(fieldName);

        setTouchedFields(newTouchedFields);
    };

    const isPristine = (fieldName: string): boolean => !touchedFields.includes(fieldName);

    const fields = currentFormProperties
        ? renderFields({
              currentData,
              customFormFields,
              errors,
              formProperties: currentFormProperties,
              handleChange,
              handleTouchedField,
              isPristine,
              originalData,
              schemaDescription,
          })
        : [];

    useEffect(() => {
        (async () => {
            try {
                await schema.validate(currentData, { abortEarly: false });
                setErrors([]);
            } catch (e) {
                setErrors((e as ValidationError).inner);
            }
        })();
    }, [currentData]);

    useEffect(() => {
        handleGetFormProperties();
    }, [currentData]);

    useEffect(() => {
        handleGetFormProperties();
    }, []);

    useEffect(() => {
        onValidation && onValidation(saveAllowed);
    }, [saveAllowed, currentData]);

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
            }}
        >
            <div style={{ width: '100%', maxWidth: '700px' }}>
                {fields}
                {onSubmit && (
                    <>
                        <Divider />
                        <Space style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            {showCancelButton && (
                                <Button shape="round" onClick={cancel}>
                                    Annuleren
                                </Button>
                            )}
                            {!showPopUp ? (
                                <Button disabled={!saveAllowed} shape="round" type="primary" onClick={submit}>
                                    {isNew ? 'Maken' : 'Updaten'}
                                </Button>
                            ) : (
                                <Popover
                                    content={'Project is succesvol geupdate!'}
                                    title="Updated"
                                    trigger="click"
                                    open={open}
                                    onOpenChange={handleOpenChange}
                                >
                                    <Button disabled={!saveAllowed} shape="round" type="primary" onClick={submit}>
                                        {isNew ? 'Maken' : 'Updaten'}
                                    </Button>
                                </Popover>
                            )}
                        </Space>
                    </>
                )}
            </div>
        </div>
    );
};
