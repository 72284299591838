import React from 'react';
import List from 'antd/lib/list';
import { Document } from '@src/utils/document';
import DocumentItem from './DocumentItem';
import useCollection from '@src/hooks/useCollection';
import { DocumentType } from '@src/models/documentType';
import { Spin } from 'antd';

export type DocumentListProps = {
    documents: Document[];
    onDocumentDelete: (document: Document) => void;
    onDocumentArchive?: (document: Document) => void;
    showArchived?: boolean;
};

const DocumentList: React.FC<DocumentListProps> = ({
    documents,
    onDocumentDelete,
    onDocumentArchive,
    showArchived = false,
}) => {
    const documentTypes = useCollection(DocumentType);

    if (!documentTypes) {
        return <Spin />;
    }

    const filteredDocuments = documents.filter((item) => (showArchived ? true : item.archived !== true));

    return (
        <List>
            {filteredDocuments.map((document) => {
                return (
                    <DocumentItem
                        key={document.id}
                        document={document}
                        documentTypes={documentTypes}
                        onDocumentDelete={onDocumentDelete}
                        onDocumentArchive={onDocumentArchive}
                    />
                );
            })}
        </List>
    );
};

export default DocumentList;
