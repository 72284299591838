import React, { CSSProperties, Suspense } from 'react';
import Layout from '@src/components/Layout';
import { BrowserRouter, Route, Switch, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react';
import userStore from '@src/stores/User';
import SignInButton from '@src/components/SignInButton';
import { Spin } from 'antd';

import ErrorBoundary from '@src/components/ErrorBoundary';
import logo from '../../images/codecapi_logo.png';
import { DEPLOYMENT_MODE, settings } from '@src/settings';
import { User } from '@src/models/user';
import { Customer } from '@src/models/customer';
import { Supplier } from '@src/models/supplier';
import { ExternalConsultant } from '@src/models/externalConsultant';
import { Employee } from '@src/models/employee';
import { Project } from '@src/models/project';
import { Offer } from '@src/models/offer';

const Dashboard = React.lazy(() => import(/* webpackChunkName: "dashboard" */ '../../pages/dashboard'));
const Users = React.lazy(() => import(/* webpackChunkName: "users" */ '../../pages/users'));
const Customers = React.lazy(() => import(/* webpackChunkName: "customers" */ '../../pages/customers'));
const Projects = React.lazy(() => import(/* webpackChunkName: "projects" */ '../../pages/projects'));
const Employees = React.lazy(() => import(/* webpackChunkName: "employees" */ '../../pages/employees'));
const SalesInvoices = React.lazy(() => import(/* webpackChunkName: "salesInvoices" */ '../../pages/salesInvoices'));
const SalesDashboard = React.lazy(() => import(/* webpackChunkName: "salesDashboard" */ '../../pages/salesDashboard'));
const PurchaseInvoices = React.lazy(
    () => import(/* webpackChunkName: "purchaseInvoices" */ '../../pages/purchaseInvoices'),
);
const Settings = React.lazy(() => import(/* webpackChunkName: "settings" */ '../../pages/settings'));
const ProjectDetail = React.lazy(
    () => import(/* webpackChunkName: "projectDetail" */ '../../pages/projects/projectDetail'),
);
const EmployeeDetail = React.lazy(
    () => import(/* webpackChunkName: "employeeDetail" */ '../../pages/employees/employeeDetail'),
);
const ProjectsDashboard = React.lazy(
    () => import(/* webpackChunkName: "projectsDashboard" */ '../../pages/projects/dashboard'),
);
const EmployeesDashboard = React.lazy(
    () => import(/* webpackChunkName: "employeesDashboard" */ '../../pages/employees/dashboard'),
);
const Suppliers = React.lazy(() => import(/* webpackChunkName: "suppliers" */ '../../pages/suppliers'));
const Inbox = React.lazy(() => import(/* webpackChunkName: "inbox" */ '../../pages/inbox'));
const Message = React.lazy(() => import(/* webpackChunkName: "message" */ '../../pages/inbox/message'));
const ExternalConsultants = React.lazy(
    () => import(/* webpackChunkName: "externalConsultants" */ '../../pages/externalConsultants'),
);
const SupplierDetail = React.lazy(
    () => import(/* webpackChunkName: "supplierDetail" */ '../../pages/suppliers/supplierDetail'),
);
const ExternalConsultantDetail = React.lazy(
    () =>
        import(
            /* webpackChunkName: "externalConsultantDetail" */ '../../pages/externalConsultants/externalConsultantDetail'
        ),
);
const Prognose = React.lazy(() => import(/* webpackChunkName: "prognose" */ '../../pages/prognosis'));
const InvoiceSchemas = React.lazy(() => import(/* webpackChunkName: "invoiceSchemas" */ '../../pages/invoiceSchema'));
const CustomerDetail = React.lazy(
    () => import(/* webpackChunkName: "customerDetail" */ '../../pages/customers/detail'),
);

const Assessments = React.lazy(() => import(/* webpackChunkName: "Assessments" */ '../../pages/assessments'));
const AssessmentDetail = React.lazy(
    () => import(/* webpackChunkName: "assessmentDetail" */ '../../pages/assessments/assessmentDetail'),
);

const EmployeeNotes = React.lazy(
    () => import(/* webpackChunkName: "employeeNotes" */ '../../pages/employees/employeeNotes'),
);
const Offers = React.lazy(() => import(/* webpackChunkName: "offers" */ '../../pages/offers'));
const OfferDetail = React.lazy(() => import(/* webpackChunkName: "offerDetail" */ '../../pages/offers/offerDetail'));
const SalaryCalculator = React.lazy(
    () => import(/* webpackChunkName: "salaryCalculato" */ '../../pages/salaryCalculator/index'),
);

// import { RatingAnswers } from '@src/models/ratingAnswers';
import { guardedRoute } from '@src/utils/guardedRoute';
import { Assessment } from '@src/models/assessment';
import DocumentTypes from '@src/pages/settings/documentTypes';
import DocumentsOverview from '@src/pages/documents';
import SalesInvoiceDeviations from '@src/pages/salesInvoiceDeviations';
import { SalesInvoiceDeviation } from '@src/models/salesInvoiceDeviation';
import InvoiceDeviationDetail from '@src/pages/salesInvoiceDeviations/detail';
import SalesInvoiceDetail from '@src/pages/salesInvoices/detail';
import { SalesInvoice } from '@src/models/salesInvoice';

const loginRootStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
};
const LoginPage = observer(() => (
    <div style={loginRootStyle}>
        <img style={{ maxWidth: '100%' }} src={logo} />
        <h1>Backoffice</h1>
        {settings.deploymentMode === DEPLOYMENT_MODE.STAGING && <h2>STAGING</h2>}
        {userStore.loading && <Spin />}
        <SignInButton disabled={userStore.loading} onClick={userStore.login} />
        {userStore.user && !userStore.firestoreUser?.admin && <div>You do not seem to have admin permissions.</div>}
    </div>
));

const RouterContent: React.FC = () => {
    const location = useLocation();
    return (
        <Layout>
            <ErrorBoundary key={location.pathname}>
                <Switch>
                    <Suspense fallback={<div>Loading...</div>}>
                        <Route exact path="/" component={Dashboard} />
                        <Route exact path={`/${User.collection}`} component={Users} />
                        <Route
                            exact
                            path={`/${Customer.collection}/:id`}
                            render={guardedRoute('readRelations', CustomerDetail)}
                        />
                        <Route
                            exact
                            path={`/${Customer.collection}`}
                            render={guardedRoute('readRelations', Customers)}
                        />
                        <Route
                            exact
                            path={`/${Supplier.collection}/:id`}
                            render={guardedRoute('readRelations', SupplierDetail)}
                        />
                        <Route
                            exact
                            path={`/${Supplier.collection}`}
                            render={guardedRoute('readRelations', Suppliers)}
                        />
                        <Route
                            exact
                            path={`/${Employee.collection}/overview`}
                            render={guardedRoute('readEmployees', Employees)}
                        />
                        <Route
                            exact
                            path={`/${Employee.collection}/:id/notes`}
                            render={guardedRoute('writeEmployees', EmployeeNotes)}
                        />
                        <Route
                            exact
                            path={`/${Employee.collection}/:id`}
                            render={guardedRoute('readEmployees', EmployeeDetail)}
                        />
                        <Route
                            exact
                            path={`/${Employee.collection}`}
                            render={guardedRoute('readEmployees', EmployeesDashboard)}
                        />
                        <Route
                            exact
                            path={`/${Offer.collection}/:id`}
                            render={guardedRoute('readOffers', OfferDetail)}
                        />
                        <Route
                            exact
                            path={`/${Assessment.collection}/overview`}
                            render={guardedRoute('readEmployees', Assessments)}
                        />
                        <Route
                            exact
                            path={`/${Assessment.collection}/:id`}
                            render={guardedRoute('readEmployees', AssessmentDetail)}
                        />
                        <Route exact path={`/${Offer.collection}`} render={guardedRoute('readOffers', Offers)} />
                        <Route
                            exact
                            path={`/salary_calculator`}
                            render={guardedRoute('readOffers', SalaryCalculator)}
                        />
                        <Route
                            exact
                            path={`/${ExternalConsultant.collection}/:id`}
                            render={guardedRoute('readRelations', ExternalConsultantDetail)}
                        />
                        <Route
                            exact
                            path={`/${ExternalConsultant.collection}`}
                            render={guardedRoute('readRelations', ExternalConsultants)}
                        />
                        <Route
                            exact
                            path={`/${Project.collection}/overview`}
                            render={guardedRoute('readProjects', Projects)}
                        />
                        <Route
                            exact
                            path={`/${Project.collection}/:id`}
                            render={guardedRoute('readProjects', ProjectDetail)}
                        />
                        <Route
                            exact
                            path={`/${Project.collection}`}
                            render={guardedRoute('readProjects', ProjectsDashboard)}
                        />
                        <Route
                            exact
                            path="/purchase-invoices"
                            render={guardedRoute('readInvoices', PurchaseInvoices)}
                        />
                        <Route
                            exact
                            path={`/${SalesInvoice.collection}`}
                            render={guardedRoute('readInvoices', SalesInvoices)}
                        />
                        <Route
                            exact
                            path={`/${SalesInvoice.collection}/:id`}
                            render={guardedRoute('readInvoices', SalesInvoiceDetail)}
                        />
                        <Route
                            exact
                            path={`/${SalesInvoiceDeviation.collection}`}
                            render={guardedRoute('readInvoices', SalesInvoiceDeviations)}
                        />
                        <Route
                            exact
                            path={`/${SalesInvoiceDeviation.collection}/:id`}
                            render={guardedRoute('readInvoices', InvoiceDeviationDetail)}
                        />
                        <Route exact path="/invoice-schema" render={guardedRoute('readInvoices', InvoiceSchemas)} />
                        <Route exact path="/sales-dashboard" render={guardedRoute('readInvoices', SalesDashboard)} />
                        <Route exact path="/prognose" render={guardedRoute('readInvoices', Prognose)} />
                        <Route exact path="/inbox/:id" render={guardedRoute('writeInvoices', Message)} />
                        <Route exact path="/inbox" render={guardedRoute('writeInvoices', Inbox)} />
                        <Route exact path="/documents" render={guardedRoute('writeProjects', DocumentsOverview)} />
                        <Route exact path="/settings" render={guardedRoute('settingsManagement', Settings)} />
                        <Route
                            exact
                            path="/settings/document-types"
                            render={guardedRoute('settingsManagement', DocumentTypes)}
                        />
                    </Suspense>
                </Switch>
            </ErrorBoundary>
        </Layout>
    );
};

const LoggedIn = () => (
    //@ts-ignore
    <BrowserRouter>
        <RouterContent />
    </BrowserRouter>
);

const App: React.FunctionComponent = () =>
    userStore.user && userStore.firestoreUser?.admin ? <LoggedIn /> : <LoginPage />;

export default observer(App);
