import { InferType } from 'yup';
import * as yup from 'yup';
import { FormPropertiesType } from '../types';

export const notificationSchema = yup.object({
    message: yup.string().label('Bericht'),
    severity: yup.string().label('Impact'),
});

type RecordType = InferType<typeof notificationSchema>;

export const formProperties: FormPropertiesType<RecordType> = {};

export type NotificationType = RecordType;
