import * as React from 'react';
import { ErrorInfo } from 'react';
import { Alert } from 'antd';

interface State {
    hasError: boolean;
}

class ErrorBoundary extends React.Component<React.PropsWithChildren, State> {
    constructor(props: { children: React.ReactNode }) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(): { hasError: boolean } {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
        // You can also log the error to an error reporting service
        console.log(error, errorInfo);
    }

    render(): React.ReactNode {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <Alert type="error" message="Something went wrong." />;
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
