import { EmployeeType } from '@src/models/employee';
import { ExternalConsultantType } from '@src/models/externalConsultant';

export const isEmployee = (item: EmployeeType | ExternalConsultantType): item is EmployeeType => {
    return typeof (item as EmployeeType).employeeNumber !== 'undefined';
};

export const isExternalConsultant = (item: EmployeeType | ExternalConsultantType): item is ExternalConsultantType => {
    return !isEmployee(item);
};
