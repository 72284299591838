export function timestampToDate(timestamp: any): Date {
    return new Date(timestamp.seconds * 1000);
}

export const convertDateToFormattedDutchDate = (date: Date): string => {
  return new Intl.DateTimeFormat('nl-NL', {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  }).format(date);
}
