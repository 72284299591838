import React, { useState } from 'react';
import { Card } from 'antd';
import { useParams } from 'react-router-dom';
import useDocument from '@src/hooks/useDocument';
import { DataDisplay, ValueRenderers } from '@src/components/DataDisplay/DataDisplay';
import DetailLayout, { DetailLayoutContent, DetailLayoutMenu } from '@src/components/DetailLayout';
import { SideMenuProps } from '@src/components/SideMenu';
import { recordLinkRenderer } from '@src/components/DataDisplay/renderers/valueRenderers';
import { DeviationType, SalesInvoiceDeviation, SalesInvoiceDeviationType } from '@src/models/salesInvoiceDeviation';
import { Project } from '@src/models/project';
import { SalesInvoice } from '@src/models/salesInvoice';
import {
    getDeviationBalance,
    getDeviationPeriod,
} from '@src/components/IncomingInvoiceWizard/utils/invoiceDeviationUtils';
import { SalesInvoiceDeviationTag } from './components/SalesInvoiceDeviationTag';
import { SalesInvoiceDeviationTimeline } from './components/SalesInvoiceDeviationTimeline';
import { SolveManuallyModal } from './components/SolveManuallyModal';

const InvoiceDeviationDetail: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const [modalOpen, setModalOpen] = useState(false);
    const [salesInvoiceDeviationDoc] = useDocument<SalesInvoiceDeviationType>(SalesInvoiceDeviation, id);
    const salesInvoiceDeviation = salesInvoiceDeviationDoc?.data();
    const valueRenderers: ValueRenderers = {
        project: recordLinkRenderer(Project),
        invoice: recordLinkRenderer(SalesInvoice),
        period: (_, record) => getDeviationPeriod(record),
        amount: (value, item) => `${value} (${getDeviationBalance(item).toString()} openstaand)`,
        type: (value) => (value === DeviationType.SHORTAGE ? 'Tekort' : 'Overschot'),
        state: (value) => <SalesInvoiceDeviationTag state={value} />,
    };

    const actions: SideMenuProps['items'] = [
        {
            label: 'Handmatig afhandelen',
            type: 'edit',
            onClick: () => setModalOpen(true),
            disabled: salesInvoiceDeviation?.state === 'SOLVED',
        },
    ];

    return salesInvoiceDeviation ? (
        <>
            {modalOpen && <SolveManuallyModal open={modalOpen} onClose={() => setModalOpen(false)} deviationId={id} />}
            <DetailLayout title={`Uurafwijking`}>
                <DetailLayoutContent>
                    <Card title={'Informatie'} style={{ marginBottom: 16 }}>
                        <DataDisplay
                            displayFields={['project', 'invoice', 'period', 'amount', 'type', 'state']}
                            schemaDescription={SalesInvoiceDeviation.schemaDescription}
                            value={salesInvoiceDeviation as any}
                            display="list"
                            valueRenderer={valueRenderers}
                        />
                    </Card>
                    {salesInvoiceDeviation && <SalesInvoiceDeviationTimeline value={salesInvoiceDeviation} />}
                </DetailLayoutContent>
                {actions.length > 0 && <DetailLayoutMenu items={actions} />}
            </DetailLayout>
        </>
    ) : (
        <div />
    );
};

export default InvoiceDeviationDetail;
