import React from 'react';
import { WizardStepProps } from '@src/components/Wizard/Wizard';
import SelectAnswer from '../../Wizard/stepTypes/SelectAnswer';
import { DocumentScope, DocumentType, DocumentTypeType } from '@src/models/documentType';
import useCollection from '@src/hooks/useCollection';

export type DocumentTypeProps = {
    onChange: (type: DocumentTypeType) => void;
    value: DocumentTypeType;
    scope: DocumentScope;
} & WizardStepProps;

const SelectDocumentType: React.FC<DocumentTypeProps> = ({ onChange, value, onStepComplete, scope }) => {
    const documentTypes = useCollection(DocumentType);
    const allowedDocuments = documentTypes.filter(
        (documentType) =>
            (documentType.allowedFor || []).includes(scope) || (documentType.requiredFor || []).includes(scope),
    );

    return (
        <SelectAnswer
            dataSource={allowedDocuments.map((item) => ({
                description: item.description,
                title: item.name,
                value: item.id,
            }))}
            onChange={(value) => onChange(allowedDocuments.find((item) => item.id === value) as DocumentTypeType)}
            onStepComplete={onStepComplete}
            value={value?.id}
        />
    );
};

export default SelectDocumentType;
