import React, { useEffect } from 'react';
import { Checkbox, List, Radio, Typography } from 'antd';
import { WizardStepProps } from '../Wizard';

const { Text } = Typography;

export type SelectAnswerItem<V = any> = { value: V; title: string; description?: string };

export type SelectAnswerProps = {
    dataSource: SelectAnswerItem[];
    title?: string;
} & WizardStepProps;

export type SelectSingleAnserProps = SelectAnswerProps & {
    onChange: (value: any) => void;
    value: any;
    type?: 'single';
};

export type SelectMultipleAnswerProps = SelectAnswerProps & {
    type: 'multiple';
    onChange: (value: string[]) => void;
    value: string[];
};

const SelectAnswer: React.FC<SelectMultipleAnswerProps | SelectSingleAnserProps> = ({
    onChange,
    value,
    type,
    dataSource,
    onStepComplete,
    title,
}) => {
    const multiple = type === 'multiple';
    const handleChange = (changedValue: string) => {
        if (type === 'multiple') {
            const isSelected = value.includes(changedValue);
            onChange(isSelected ? value.filter((item) => item !== changedValue) : [...value, changedValue]);
            onStepComplete(value.length > 0);
        } else {
            onChange(changedValue);
            onStepComplete(true);
        }
    };

    return (
        <>
            {title && <Text strong>{title}</Text>}
            <List
                itemLayout="horizontal"
                dataSource={dataSource}
                renderItem={(item) => {
                    const itemCompleted = multiple ? value.includes(item.value) : value === item.value;

                    return (
                        <List.Item
                            onClick={() => handleChange(item.value)}
                            key={item.value}
                            style={{ cursor: 'pointer' }}
                        >
                            <List.Item.Meta
                                avatar={
                                    multiple ? (
                                        <Checkbox checked={itemCompleted} />
                                    ) : (
                                        <Radio value={item.value} checked={value === item.value} />
                                    )
                                }
                                title={<Text strong={typeof item.description !== 'undefined'}>{item.title}</Text>}
                                description={item.description}
                            />
                        </List.Item>
                    );
                }}
            />
        </>
    );
};

export default SelectAnswer;
