import { InferType } from 'yup';
import * as yup from 'yup';
import { FormPropertiesType } from '../types';
import { Model } from '..';

export const yearBoundFileMetaSchema = yup.object({
    year: yup.string().label('Jaargang'),
});

type RecordType = InferType<typeof yearBoundFileMetaSchema>;

const formProperties: FormPropertiesType<RecordType> = {
    year: {
        default: new Date().getFullYear().toString(),
    },
};

export const YearBoundFileMeta: Model<RecordType> = {
    collection: '',
    schema: yearBoundFileMetaSchema,
    schemaDescription: yearBoundFileMetaSchema.describe().fields,
    formProperties,
    defaultDisplay: (data) => data.year || '',
};

export type YearBoundFileMetaType = RecordType;
