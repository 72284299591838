import * as yup from 'yup';

yup.addMethod(yup.mixed, 'reference', function (key) {
    return this.meta({ model: key });
});

export type FormProperty<T> = {
    readonly?: boolean;
    hidden?: boolean;
    textarea?: boolean;
    radioButtonFieldEmployee?: boolean;
    radioButtonFieldLegal?: boolean;
    radioButtonFieldCurrency?: boolean;
    radioButtonFieldLanguage?: boolean;
    radioButtonFieldSupplierType?: boolean;
    options?: string[] | { value: string; label: string }[];
    default?: T | Partial<T> | (() => Partial<T>);
};
export type FormPropertiesType<T> = {
    [key in keyof T]?: FormProperty<T[key]>;
};
