import {
    getDeviationBalanceForCalculation,
    getDeviationPeriod,
    getIntermediateDeviationBalance,
} from '@src/components/IncomingInvoiceWizard/utils/invoiceDeviationUtils';
import RecordLink from '@src/components/RecordLink';
import { SalesInvoice, SalesInvoiceType } from '@src/models/salesInvoice';
import { DeviationType, SalesInvoiceDeviationType } from '@src/models/salesInvoiceDeviation';
import { db } from '@src/services/firebaseService';
import { StepProps, Steps } from 'antd';
import { Typography } from 'antd';
import { collection, getDocs, or, query, where } from 'firebase/firestore';
import moment from 'moment';
import React, { useEffect } from 'react';
import { SalesInvoiceDeviationTag } from './SalesInvoiceDeviationTag';

export type SalesInvoiceDeviationTimelineProps = {
    value: SalesInvoiceDeviationType;
};

const getDeviationStep = (deviation: SalesInvoiceDeviationType, invoice?: SalesInvoiceType): StepProps => {
    return {
        title: (
            <>
                {deviation.type === DeviationType.SHORTAGE ? 'Tekort' : 'Overschot'} van {deviation.amount} uur
                geconstateerd in factuur <RecordLink id={invoice?.id || ''} model={SalesInvoice} />
            </>
        ),
        subTitle: `op ${moment(invoice?.date).format('DD-MM-YYYY')}`,
        description: `Deze afwijking is geconstateerd over de periode ${getDeviationPeriod(deviation)}`,
    };
};

const getDeviationSollutionSteps = (
    deviation: SalesInvoiceDeviationType,
    invoices?: SalesInvoiceType[],
): StepProps[] => {
    return deviation.sollution.map<StepProps>((sollution, index) => {
        const invoice = invoices?.find((item) => (sollution.invoice ? item.id === sollution.invoice.id : false));
        const balanceAfterSollution = getIntermediateDeviationBalance(
            deviation,
            deviation.sollution.slice(0, index + 1),
        );

        return invoice
            ? {
                  title: (
                      <>
                          {sollution.amount} uur verrekend met{' '}
                          {sollution.type === DeviationType.SHORTAGE ? 'tekort' : 'overschot'} in factuur{' '}
                          <RecordLink id={invoice?.id || ''} model={SalesInvoice} />
                      </>
                  ),
                  subTitle: `op ${moment(invoice.date).format('DD-MM-YYYY')}`,
                  description: `Saldo van de afwijking is na deze verrekening gewijzigd naar ${balanceAfterSollution} uur`,
              }
            : sollution.manualCorrection
            ? {
                  title: `Handmatige correctie van ${sollution.amount} uur`,
                  subTitle: `Reden: ${sollution.description}`,
                  description: `Saldo van de afwijking is na deze verrekening gewijzigd naar ${balanceAfterSollution} uur`,
              }
            : { description: 'onbekende factuur' };
    });
};

export const SalesInvoiceDeviationTimeline: React.FC<SalesInvoiceDeviationTimelineProps> = ({ value }) => {
    const [resolvedInvoices, setResolvedInvoices] = React.useState<SalesInvoiceType[]>();
    const mainInvoice = resolvedInvoices?.find((item) => item.id === value.invoice.id);
    const timeline: StepProps[] = [
        getDeviationStep(value, mainInvoice),
        ...(value.sollution && value.sollution.length > 0 ? getDeviationSollutionSteps(value, resolvedInvoices) : []),
        ...(value.state === 'SOLVED' ? [{ title: <SalesInvoiceDeviationTag state="SOLVED" /> }] : []),
    ];

    const doResolveInvoices = async () => {
        const collectionRef = collection(db, SalesInvoice.collection);
        const invoiceRefs = [
            value.invoice,
            ...(value.sollution || []).filter((item) => item.invoice !== null).map((s) => s.invoice),
        ];
        const q = query(collectionRef, or(...(invoiceRefs.map((ref) => where('id', '==', ref.id)) as any)));
        const docs = await getDocs(q);
        setResolvedInvoices(docs.docs.map((doc) => doc.data() as SalesInvoiceType));
    };

    useEffect(() => {
        if (value && !resolvedInvoices) {
            doResolveInvoices();
        }
    }, [value]);

    return (
        <div style={{ marginTop: 36 }}>
            <Typography.Title level={5}>Tijdlijn</Typography.Title>
            <Steps progressDot current={5} direction="vertical" items={timeline} />
        </div>
    );
};
