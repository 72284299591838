import { InferType } from 'yup';
import * as yup from 'yup';
import { FormPropertiesType } from './types';
import { Model } from '.';
import { fileSchema } from './sub/file';
import { notificationSchema } from './sub/notification';

const schema = yup.object({
    id: yup.string().required().label('ID'),
    firstname: yup.string().required().label('Voornaam'),
    lastname: yup.string().required().label('Achternaam'),
    dateOfBirth: yup.date().required().label('Geboortedatum'),
    supplier: yup.mixed().required().label('Leverancier/tussenpartij'),
    files: yup.array(fileSchema),
    driveFolderID: yup.string(),
    notifications: yup.array(notificationSchema),
});

type RecordType = InferType<typeof schema>;

const formProperties: FormPropertiesType<RecordType> = {
    id: {
        readonly: true,
    },
    files: {
        hidden: true,
    },
    driveFolderID: {
        hidden: true,
    },
    notifications: {
        hidden: true,
    },
};
export const ExternalConsultant: Model<RecordType> = {
    collection: 'external_consultants',
    schema,
    schemaDescription: schema.describe().fields,
    formProperties,
    defaultDisplay: (data) => data.firstname + ' ' + data.lastname,
};

export type ExternalConsultantType = RecordType;
