import { Documents } from '@src/models/documents';
import useDocument from './useDocument';

export const useDocumentNotificationsCount = (): number => {
    const [documents] = useDocument(Documents, 'documents');
    const data = documents?.data();
    const highNotifications = data?.notifications?.filter((notification) => notification.severity === 'HIGH');

    return highNotifications?.length || 0;
};
