import React from 'react';
import { Button } from 'antd';
import { Model } from '@src/models';
import { FormPropertiesType } from '@src/models/types';
import { FieldProps } from '@src/components/Form/fields';
import PlusOutlined from '@ant-design/icons/PlusOutlined';
import AddEditRecordModal from '../AddEditRecordModal';

interface AddFirebaseRecordProps<T extends { id: string }> {
    model: Model<T>;
    newRecord?: () => T | Promise<T>;
    changeHook?: (oldValue: T, newValue: T) => T | Promise<T>;
    getFormProperties?: (value: T) => FormPropertiesType<T>;
    customFormFields?: { [key in keyof Partial<T>]: React.ComponentType<FieldProps<T>> };
    value?: string;
}
const AddRecordButton = <T extends { id: string }>({
    model,
    newRecord,
    changeHook,
    getFormProperties,
    customFormFields,
    value,
}: AddFirebaseRecordProps<T>): JSX.Element => {
    const [isOpen, setIsOpen] = React.useState<boolean>(false);

    const handleClick = () => {
        setIsOpen(true);
    };

    return (
        <>
            <Button shape="round" type="primary" icon={<PlusOutlined />} onClick={handleClick}>
                {value || 'Nieuwe'}
            </Button>
            {isOpen && (
                <AddEditRecordModal
                    isOpen={isOpen}
                    model={model}
                    onCancel={() => setIsOpen(false)}
                    newRecord={newRecord}
                    changeHook={changeHook}
                    customFormFields={customFormFields}
                    getFormProperties={getFormProperties as any}
                    type={value}
                    onComplete={() => setIsOpen(false)}
                />
            )}
        </>
    );
};

export default AddRecordButton;
