import { InferType } from 'yup';
import * as yup from 'yup';
import { FormPropertiesType } from '../types';
import { Model } from '..';

export const timedFileMetaSchema = yup.object({
    from: yup.date().nullable().label('Document (ingang) datum'),
    expire: yup.date().nullable().label('Document vervaldatum'),
});

type RecordType = InferType<typeof timedFileMetaSchema>;

const formProperties: FormPropertiesType<RecordType> = {
    expire: {
        default: null,
    },
    from: {
        default: null,
    },
};

export const TimedFileMeta: Model<RecordType> = {
    collection: '',
    schema: timedFileMetaSchema,
    schemaDescription: timedFileMetaSchema.describe().fields,
    formProperties,
    defaultDisplay: (data) => data.from + ' - ' + data.expire,
};

export type TimedFileMetaType = RecordType;
