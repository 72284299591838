import { InferType } from 'yup';
import * as yup from 'yup';
import { Model } from '.';
import { FormPropertiesType } from './types';

export enum DocumentScope {
    PROJECT_EMPLOYEE = 'project-employee',
    PROJECT_SELF_EMPLOYED = 'project-self-employed',
    PROJECT_SUPPLIER = 'project-supplier',
    EMPLOYEE = 'employee',
    EXTERNAL_CONSULTANT = 'external-consultant',
    CUSTOMER = 'customer',
    SUPPLIER = 'supplier',
    SUPPLIER_SELF_EMPLOYED = 'supplier-self-employed',
    SUPPLIER_MEDIATOR = 'supplier-mediator',
    DOCUMENTS = 'documents',
}

const documentScopeOptions = [
    { value: DocumentScope.PROJECT_EMPLOYEE, label: 'Project uitgevoerd door eigen medewerker' },
    { value: DocumentScope.PROJECT_SELF_EMPLOYED, label: 'Project uitgevoerd door ZZP-er' },
    { value: DocumentScope.PROJECT_SUPPLIER, label: 'Project uitgevoerd door ingeleende medewerker' },
    { value: DocumentScope.EMPLOYEE, label: 'Personeelslid' },
    { value: DocumentScope.EXTERNAL_CONSULTANT, label: 'Externe consultant' },
    { value: DocumentScope.CUSTOMER, label: 'Klant' },
    { value: DocumentScope.SUPPLIER, label: 'Leverancier' },
    { value: DocumentScope.SUPPLIER_SELF_EMPLOYED, label: 'Leverancier (zelfstandig zonder personeel)' },
    { value: DocumentScope.SUPPLIER_MEDIATOR, label: 'Leverancier (bemiddelaar)' },
    { value: DocumentScope.DOCUMENTS, label: 'Documenten belangrijk voor bedrijfsvoering' },
];

export enum DocumentMetaType {
    NONE = 'none',
    CUSTOM_EXPIRATION = 'custom-expiration',
    DATED = 'dated',
    THREE_MONTHLY_EXPIRATION = 'three-monthly-expiration',
    YEARLY_EXPIRATION = 'yearly-expiration',
    YEAR_BOUND = 'year-bound',
}

const documentMetaOptions = [
    { value: DocumentMetaType.NONE, label: 'Geen' },
    { value: DocumentMetaType.CUSTOM_EXPIRATION, label: 'Met invulbare vervaldatum' },
    { value: DocumentMetaType.DATED, label: 'Met documentdatum' },
    { value: DocumentMetaType.THREE_MONTHLY_EXPIRATION, label: 'Drie maanden geldig vanaf documentdatum' },
    { value: DocumentMetaType.YEAR_BOUND, label: 'Jaargebonden' },
    { value: DocumentMetaType.YEARLY_EXPIRATION, label: 'Eén jaar geldig vanaf documentdatum' },
];

const schema = yup.object({
    id: yup.string().required().label('ID'),
    name: yup.string().required().label('Documentnaam'),
    slug: yup.string().required().label('Slug (old reference method)'),
    description: yup.string().required().label('Beschrijving'),
    folderName: yup.string().required().label('Mapnaam'),
    color: yup.string().required().label('Kleur'),
    metaType: yup.string().oneOf(Object.values(DocumentMetaType)).required().label('Metatype'),
    allowedFor: yup.array(yup.string().oneOf(Object.values(DocumentScope)).required()).label('Toegestaan voor'),
    requiredFor: yup.array(yup.string().oneOf(Object.values(DocumentScope)).required()).label('Vereist voor'),
});

type RecordType = InferType<typeof schema>;

const formProperties: FormPropertiesType<RecordType> = {
    id: {
        readonly: true,
    },
    allowedFor: {
        options: documentScopeOptions,
    },
    requiredFor: {
        options: documentScopeOptions,
    },
    metaType: {
        options: documentMetaOptions,
    },
};

export const DocumentType: Model<RecordType> = {
    collection: 'document_types',
    schema,
    schemaDescription: schema.describe().fields,
    formProperties,
    defaultDisplay: (data) => data.name,
};

export type DocumentTypeType = RecordType;
