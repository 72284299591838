import { InferType } from 'yup';
import * as yup from 'yup';

export const generatedDocumentSchema = yup.object({
    status: yup.string().oneOf(['IDLE', 'GENERATING_PREVIEW', 'FINALIZING', 'FINALIZED']),
    name: yup.string().required().label('Naam'),
    draftID: yup.string(),
    pdfID: yup.string(),
    templateID: yup.string(),
    creationDate: yup.mixed().label('Gemaakt op'),
});

export type GeneratedDocumentType = InferType<typeof generatedDocumentSchema>;
