import * as yup from 'yup';
import { FormPropertiesType } from './types';
import { Model } from '.';
import { Timestamp } from 'firebase/firestore';
import { EmployeeType } from './employee';

export const assessmentStatusLabels = ['Nieuw', 'Bezig', 'Klaar voor review', 'Klaar'];

export const schema = yup.object({
    id: yup.string().label('ID'),
    name: yup.string().required().label('Titel'),
    employee: yup.mixed<EmployeeType>().reference('employee').required().label('Medewerker'),
    reviewer: yup.mixed<EmployeeType>().reference('employee').label('Beoordelaar'),
    reviewerNotes: yup.string().label('Beoordelaar opmerkingen'),
    createdAt: yup.mixed<Timestamp>().meta({ timestamp: true }).required().label('Aanmaakdatum'),
    updatedAt: yup.mixed<Timestamp>().meta({ timestamp: true }).required().label('Datum bijgewerkt'),
    status: yup.number().required().min(0).max(3).label('Status'),
});

type RecordType = yup.InferType<typeof schema>;

const currentDate = new Date();
const formattedDate = `${currentDate.getDate()}-${currentDate.getMonth() + 1}-${currentDate.getFullYear()}`;
const defaultRatingName = `Beoordeling ${formattedDate}`;

const formProperties: FormPropertiesType<RecordType> = {
    id: { hidden: true },
    name: { default: defaultRatingName },
    status: { hidden: true, default: 0 },
    reviewer: { hidden: true },
    reviewerNotes: { hidden: true },
    createdAt: { hidden: true, default: Timestamp.now() },
    updatedAt: { hidden: true, default: Timestamp.now() },
};

export const Assessment: Model<RecordType> = {
    collection: 'assessments',
    schema,
    schemaDescription: schema.describe().fields,
    formProperties,
    defaultDisplay: (data) => data.name,
};

export type AssessmentType = RecordType;
