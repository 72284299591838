import { InferType } from 'yup';
import * as yup from 'yup';
import { FormPropertiesType } from './types';
import { Model } from '.';
import { fileSchema } from './sub/file';
import { notificationSchema } from './sub/notification';

const schema = yup.object({
    id: yup.string().required().label('ID'),
    driveFolderID: yup.string(),
    status: yup.string().oneOf(['IDLE', 'GENERATING_PREVIEW', 'FINALIZING', 'FINALIZED']),
    draftID: yup.string().nullable(true),
    pdfID: yup.string().nullable(true),
    name: yup.string().required().label('Bedrijfsnaam'),
    legalForm: yup.string().required('Kies een van de 4 opties').label('Rechtsvorm'),
    supplierType: yup.string().required('Kies een van de 3 opties').label('Leverancier type'),
    address: yup.string().required().label('Adres'),
    city: yup.string().required().label('Stad'),
    postalCode: yup.string().required().label('Postcode'),
    country: yup.string().required().label('Land'),
    contactPerson: yup.string().label('Contactpersoon'),
    statutoryDomicile: yup.string().required().label('Vestigingsplaats'),
    director: yup.string().required().label('Directeur'),
    email: yup.string().email().label('E-mail adres'),
    accountNumber: yup.string().label('Rekeningnummer'),
    gAccountNumber: yup.string().label('G-rekeningnummer'),
    kvkNumber: yup.string().required().label('KvK nummer'),
    vatNumber: yup.string().required().label('BTW Nummer'),
    files: yup.array(fileSchema),
    notifications: yup.array(notificationSchema),
});

type RecordType = InferType<typeof schema>;

const formProperties: FormPropertiesType<RecordType> = {
    id: {
        readonly: true,
    },
    country: {
        default: 'Nederland',
    },
    legalForm: {
        radioButtonFieldLegal: true,
    },
    supplierType: {
        radioButtonFieldSupplierType: true,
    },
    draftID: {
        hidden: true,
    },
    driveFolderID: {
        hidden: true,
    },
    pdfID: {
        hidden: true,
    },
    status: {
        hidden: true,
        default: 'IDLE',
    },
    files: {
        hidden: true,
    },
    notifications: {
        hidden: true,
    },
};
export const Supplier: Model<RecordType> = {
    collection: 'suppliers',
    schema,
    schemaDescription: schema.describe().fields,
    formProperties,
    defaultDisplay: (data) => data.name,
};

export type SupplierType = RecordType;
