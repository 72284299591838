import { InferType } from 'yup';
import * as yup from 'yup';
import { FormPropertiesType } from './types';
import { Model } from '.';

const schema = yup.object({
    id: yup.string().required().label('ID'),
    invoiceNumberCounter: yup.number().required().label('Invoice nummer teller'),
    htmlEmailTemplate: yup.string().label('HTML email template'),
    customerMailSubject: yup.string().label('Klant email onderwerp'),
    customerMailTemplate: yup.string().label('Klant email'),
    customerMailReminder1Subject: yup.string().label('Herinnering 1 onderwerp'),
    customerMailReminder1Template: yup.string().label('Herinnering 1 email'),
    customerMailReminder2Subject: yup.string().label('Herinnering 2 onderwerp'),
    customerMailReminder2Template: yup.string().label('Herinnering 2 email'),
    customerMailReminder3Subject: yup.string().label('Herinnering 3 onderwerp'),
    customerMailReminder3Template: yup.string().label('Herinnering 3 email'),
    customerMailReminder4Subject: yup.string().label('Herinnering 4 onderwerp'),
    customerMailReminder4Template: yup.string().label('Herinnering 4 email'),
    supplierMailAcceptedTemplate: yup.string().label('Leverancier factuur geaccepteerd email'),
    supplierMailRejectedTemplate: yup.string().label('Leverancier factuur afgewezen email'),
    newAssessmentEmailTemplate: yup.string().label('Nieuwe beoordeling email template'),
    mailer: yup
        .object({
            oauth: yup.object({
                access_token: yup.string(),
                expiry_date: yup.number(),
                id_token: yup.string(),
                refresh_token: yup.string(),
                scope: yup.string(),
                token_type: yup.string(),
            }),
            userinfo: yup.object({
                email: yup.string(),
                family_name: yup.string(),
                given_name: yup.string(),
                hd: yup.string(),
                id: yup.string(),
                locale: yup.string(),
                name: yup.string(),
                picture: yup.string().url(),
                verified_email: yup.boolean(),
            }),
        })
        .label('Mail account'),
});

type RecordType = InferType<typeof schema>;

const formProperties: FormPropertiesType<RecordType> = {
    id: {
        readonly: true,
        hidden: true,
    },
    customerMailTemplate: {
        textarea: true,
    },
    customerMailReminder1Template: {
        textarea: true,
    },
    customerMailReminder2Template: {
        textarea: true,
    },
    customerMailReminder3Template: {
        textarea: true,
    },
    customerMailReminder4Template: {
        textarea: true,
    },
    supplierMailAcceptedTemplate: {
        textarea: true,
    },
    supplierMailRejectedTemplate: {
        textarea: true,
    },
    htmlEmailTemplate: {
        textarea: true,
    },
    mailer: {
        hidden: true,
    },
};

export const Settings: Model<RecordType> = {
    collection: 'kv',
    schema,
    schemaDescription: schema.describe().fields,
    formProperties,
    defaultDisplay: () => 'Settings',
};
export type SettingsType = RecordType;
