import React from 'react';
import { Input, InputRef } from 'antd';
import { FieldProps } from '.';

const StringField = ({ value, onChange, readonly, onBlur }: FieldProps<string>): JSX.Element => {
    const inputRef = React.useRef<InputRef>(null);

    return (
        <Input
            disabled={readonly}
            ref={inputRef}
            defaultValue={value}
            onBlur={(e) => {
                onChange(e.target.value);
                onBlur();
            }}
        />
    );
};

export default StringField;
