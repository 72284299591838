import React from 'react';
import { observer } from 'mobx-react';
import userStore from '@src/stores/User';
import { Avatar, Dropdown, Row, Col } from 'antd';

import logo from '../../images/codecapi_logo.png';

const Header = () => {
    const user = userStore.user!;

    return (
        <Row style={{ height: '100%', justifyContent: 'space-between' }}>
            <Col style={{ height: '100%' }}>
                <img style={{ height: 24, marginLeft: -25 }} src={logo} />
            </Col>
            <Col style={{ fontWeight: 100, fontSize: '.8em' }}>
                <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://console.cloud.google.com/logs?project=facturatie-1dc8d"
                    style={{ marginRight: '2em' }}
                >
                    Logs
                </a>
                <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://console.firebase.google.com/u/3/project/facturatie-1dc8d/database1"
                    style={{ marginRight: '2em' }}
                >
                    Firestore
                </a>
                <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://drive.google.com/drive/u/0/folders/1MQ9Sm9aEy0dEC3o1ri-wDx32cmZQCHQh"
                    style={{ marginRight: '2em' }}
                >
                    Drive
                </a>
                <span style={{ marginRight: '1em' }}>{user.displayName}</span>
                <Dropdown
                    menu={{ items: [{ label: <a onClick={userStore.logout}>Uitloggen</a>, key: 'logout' }] }}
                    trigger={['click']}
                >
                    <a>
                        <Avatar src={user.photoURL || ''} />
                    </a>
                </Dropdown>
            </Col>
        </Row>
    );
};

export default observer(Header);
