import React from 'react';
import { Radio } from 'antd';
import './radioButtonField.css';
import { FieldProps } from '..';

const RadioButtonFieldEmployeeType = ({ value, onChange, readonly }: FieldProps<boolean>): JSX.Element => {
    return (
        <Radio.Group buttonStyle="solid" disabled={readonly} onChange={(e) => onChange(e.target.value)} value={value}>
            <Radio.Button value="Intern">Intern</Radio.Button>
            <Radio.Button value="ZZP">ZZP</Radio.Button>
            <Radio.Button value="Consultancy">Consultancy</Radio.Button>
        </Radio.Group>
    );
};

export default RadioButtonFieldEmployeeType;
